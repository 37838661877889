import React, { useContext, useEffect, useState, useMemo } from "react";
import * as R from "ramda";
import { Loading } from "@foris/avocado-ui";
import { AppContext } from "../context/EditSessionsContext";
import LinkHeader from "@dimensions/link/components/HeaderLink";
import FormEdit from "./FormEdit/FormEdit";
import { useGetLink } from "../hooks/useGetLinks";
import { useGetBlocks } from "../hooks/useGetBlocks";
import ResultEdit from "./ResultEdit/ResultEdit";
import AdvanceSearchInstructor from "../pages/FormEdit/Instructor/AdvanceSearch";
import AdvanceSearchClassroom from "../pages/FormEdit/Classroom/AdvanceSearch";
import css from "./../pages/FormEdit/formEdit.module.scss";
import { useGetSessionsRecommendations } from "../hooks/useGetSessionsRecommendation";
import { Types } from "../context/linkData.reducer";

const InitEditSessions: React.FC = () => {
  const { dispatch, state } = useContext(AppContext);
  const [dataLinks, getDataLinks] = useGetLink();
  const [dataBlocks, getDataBlocks] = useGetBlocks();
  const [recommendations, getRecomendations] = useGetSessionsRecommendations();
  const [heightHeader, setHeightHeder] = useState(null);

  useEffect(() => {
    if (dataLinks === null) getDataLinks();
    if (dataBlocks.dataEnd === null) getDataBlocks();
  });

  /**
   * Requets for sessions recommendations after the link info is obtained.
   */
  useMemo(() => {
    const link = state?.link;

    if (!link) return;

    const sessionIds = R.reduce(
      (acc, section) => {
        const sessions = R.map(R.prop("id"), section?.sessions ?? []);
        const unasignedSessions = R.map(R.prop("id"), section?.unasignedSessions ?? []);
        return R.concat(acc, R.concat(sessions, unasignedSessions));
      },
      [],
      link?.sections ?? [],
    );

    getRecomendations(sessionIds);
  }, [dataLinks]);

  useEffect(() => {
    dispatch({ type: Types.SetRecommendations, payload: recommendations });
  }, [recommendations]);

  if (!dataLinks || !dataBlocks) return <Loading />;

  return (
    <main className={css.main}>
      {state?.link?.info && <LinkHeader link={state?.link?.info} setHeight={setHeightHeder} />}
      {state?.page?.loading && <Loading />}
      {state?.page?.active?.form && <FormEdit heightHeader={heightHeader} />}
      {state?.page?.active?.results && <ResultEdit heightHeader={heightHeader} />}
      {state?.page?.active?.advanceSearchClassroom && <AdvanceSearchClassroom />}
      {state?.page?.active?.advanceSearchInstructor && <AdvanceSearchInstructor />}
    </main>
  );
};

export default InitEditSessions;
