import React, { useEffect } from "react";
import { ContextProvider } from "../context/PackagesContext";
import Layout from "../../../../common/layout/Layout";
import CreationForm from "./CreationForm";
import { Header } from "@common/components";
import usePackagesCrud from "../hooks/usePackagesCrud";
import { useParams, useHistory } from "react-router-dom";
import { IParams } from "@models/IParams";
import { PackageCrudResult } from "@models/ISchema";
import { DataReducerType } from "../context/types";

const PackageCreation = () => {
  const history = useHistory();
  const { origin, scenario, workspace }: IParams = useParams();
  const route = `/scheduler/editor/package/${workspace}/${scenario}/${origin}`;
  const [resultPackagesCrud, submitPackagesCrud] = usePackagesCrud({ scenario, origin });

  const onPackageCrudSubmit = async (
    dryRun: boolean,
    skipValidations: boolean,
    data?: DataReducerType,
  ) => {
    if (resultPackagesCrud.isLoading) return;

    await submitPackagesCrud(data, false, skipValidations);
  };

  useEffect(() => {
    const result = resultPackagesCrud?.result as PackageCrudResult;

    if (result?.payload?.creates?.length) {
      const createdPackage = result?.payload?.creates[0]?.package;
      history.push(`${route}/${createdPackage?.id}`);
    }
  }, [resultPackagesCrud?.result]);

  return (
    <Layout contextSearch>
      <ContextProvider>
        <Header dimension="Paquete" title="Creación de Nuevo Paquete" />

        <CreationForm onSubmit={onPackageCrudSubmit} isLoading={resultPackagesCrud?.isLoading} />
      </ContextProvider>
    </Layout>
  );
};

export default PackageCreation;
