import gql from "graphql-tag";
import { FRAGMENT_SECTIONS } from "../../graphql/section.fragment";

export const packageSection = gql`
  query packageSection(
    $scenarioId: ID!
    $originId: ID!
    $resourceType: ResourceType!
    $resourceId: ID!
  ) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editorView(resourceType: $resourceType, resourceId: $resourceId) {
        info {
          __typename
          ... on PackageInfo {
            package {
              id
              code
              vacancies
              vacanciesSource
              isVisible
              isPublished
              isReplicated
              sourcePopulationCode
              sourcePackage {
                id
                code
                name
              }
              name
              packageTotalCredits
              labels {
                id
                code
                name
                category {
                  id
                  code
                  name
                  labelSource {
                    id
                    code
                    name
                  }
                }
              }
              population {
                id
                level
                curriculum
                campus {
                  id
                  code
                }
                term {
                  id
                  name
                  code
                  start_date
                  end_date
                }
                program {
                  id
                  code
                }
                modality {
                  id
                  code
                }
                shift {
                  id
                  code
                }
              }
            }
          }
        }
        ...CalendarSections
      }
      editorWeeks(resourceType: $resourceType, resourceId: $resourceId) {
        id
        name
        startingDate
        endingDate
        eventCount
        termParts {
          id
          code
          name
          category {
            id
            code
            name
          }
        }
      }
    }
  }
  ${FRAGMENT_SECTIONS}
`;
