import gql from "graphql-tag";

export const UPDATE_EVENT_MUTATION = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $input: EditSessionInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editSession(input: $input) {
        commited
        userCanSkipValidations
        skippedValidations
        customValidations {
          __typename
          code
          info {
            ... on InstructorInfo {
              __typename
              instructor {
                code
              }
            }
          }
          items {
            key
            value
          }
        }
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            __typename
          }
          ... on InstructorAssignmentNotAllowed {
            allowed
            __typename
          }
          ... on ClassroomsAssignmentNotAllowed {
            allowed
            __typename
          }
          ... on IntervalsAssignmentNotAllowed {
            allowed
            __typename
          }
          ... on BlocksAssignmentNotAllowed {
            allowed
            __typename
          }
          ... on InvalidClassroomsCapacityAssignment {
            allowed
            __typename
          }
          ... on StudentClash {
            __typename
            count
            students {
              id
              code
            }
          }
        }
        intervalValidationErrors {
          interval {
            id
            startingDate
            endingDate
            termParts {
              id
              code
              name
            }
          }

          validationErrors {
            ... on InvalidBlock {
              __typename
              isValidStartTime
              isValidEndTime
            }
            ... on ClassroomClash {
              __typename
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              block {
                startingTime
                endingTime
              }
              sessions {
                id
                section {
                  id
                }
              }
              classroom {
                id
                code
              }
            }
            ... on BookingClassroomClash {
              __typename
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              block {
                startingTime
                endingTime
              }
              sessions {
                id
                section {
                  id
                }
              }
              classroom {
                id
                code
              }
            }
            ... on InstructorClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              instructor {
                id
                code
              }
              block {
                startingTime
                endingTime
              }
            }
            ... on LinkClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              link {
                id
                vacancies
              }
            }
            ... on PackageClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              package {
                id
                code
                index
                complete
                vacancies
                hasPackageRules
                type
              }
            }
            ... on InvalidInstructorTravel {
              __typename
              instructor {
                id
                name
              }
              withPrevious {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
              withFollowing {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
            }
            ... on InvalidPackageTravel {
              __typename
              package {
                id
                code
              }
              withPrevious {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
              withFollowing {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
            }
            ... on InvalidBlockForInstructor {
              instructor {
                code
              }
            }

            ... on InstructorExceededWeeklyBlocks {
              instructor {
                code
              }
              assignableBlocks
              exceededBlocks
            }
          }
        }
      }
    }
  }
`;

export const CREATE_EVENT_MUTATION = gql`
  mutation Crear($scenarioId: ID!, $originId: ID!, $input: EditSessionInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      createSession(input: $input) {
        commited
        userCanSkipValidations
        skippedValidations
        customValidations {
          __typename
          code
          info {
            ... on InstructorInfo {
              __typename
              instructor {
                code
              }
            }
          }
          items {
            key
            value
          }
        }
        validationErrors {
          ... on StudentClash {
            __typename
            count
            students {
              id
              code
            }
          }
        }
        intervalValidationErrors {
          interval {
            id
            startingDate
            endingDate
            termParts {
              id
              code
              name
            }
          }

          validationErrors {
            ... on InvalidBlock {
              __typename
              isValidStartTime
              isValidEndTime
            }
            ... on ClassroomClash {
              __typename
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              block {
                startingTime
                endingTime
              }
              sessions {
                id
                section {
                  id
                }
              }
              classroom {
                id
                code
              }
            }
            ... on InstructorClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              instructor {
                id
                code
              }
              block {
                startingTime
                endingTime
              }
            }
            ... on LinkClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                name
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              link {
                id
                vacancies
              }
            }
            ... on PackageClash {
              __typename
              block {
                startingTime
                endingTime
              }
              interval {
                startingDate
                endingDate
                termParts {
                  id
                  code
                  name
                }
              }
              sessions {
                id
                section {
                  id
                }
              }
              package {
                id
                code
                index
                complete
                vacancies
                hasPackageRules
                type
              }
            }
            ... on InvalidInstructorTravel {
              __typename
              instructor {
                id
                name
              }
              withPrevious {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
              withFollowing {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
            }
            ... on InvalidPackageTravel {
              __typename
              package {
                id
                code
              }
              withPrevious {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
              withFollowing {
                from {
                  id
                  vacancies
                }
                to {
                  id
                  vacancies
                }
                blocksBetween
                blocksRequired
              }
            }
            ... on InvalidBlockForInstructor {
              instructor {
                code
              }
            }

            ... on InstructorExceededWeeklyBlocks {
              instructor {
                code
              }
              assignableBlocks
              exceededBlocks
            }
          }
        }
      }
    }
  }
`;

export const QUERY_RECOMMENDATIONS = gql`
  query recommendation($scenarioId: ID!, $originId: ID!, $sessionId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      sessionRecommendations(sessionId: $sessionId) {
        html
      }
    }
  }
`;
