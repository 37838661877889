import React, { useContext } from "react";
import { flowRight, uniq } from "lodash";
import { useParams, useHistory } from "react-router-dom";
import { CardState } from "@foris/avocado-ui";
import cx from "classnames";
import { IParams } from "@models/IParams";
import { BookingContext } from "../../context/BookingContext";
import { Types } from "../../context/request.reducer";
import { Types as TypesSearch } from "../../context/search.reducer";
import css from "./requestErrors.module.scss";
import { Interval, ClassroomBookingValidationErrorUnion, SessionAssignment } from "@models/ISchema";

const daysTranslations = {
  MONDAY: "Lun",
  TUESDAY: "Mar",
  WEDNESDAY: "Mie",
  THURSDAY: "Jue",
  FRIDAY: "Vie",
  SATURDAY: "Sab",
  SUNDAY: "Dom",
};

const Errors = () => {
  const { origin, scenario, workspace }: IParams = useParams();
  const { state, dispatch } = useContext(BookingContext);
  const history = useHistory();
  const errors = state?.request.errors;
  const urlContext = `${workspace}/${scenario}/${origin}`;
  const routePath = `/booking/search/${urlContext}`;

  const getAssignmentsFromErrors = (errors: ClassroomBookingValidationErrorUnion[]) => {
    const assignments = [];
    errors.forEach(error => {
      if ("sessions" in error) {
        error.sessions.forEach(({ assignment }) => assignments.push(assignment));
      }
    });
    return assignments;
  };

  const getErrorsFromAssignments = (assignments: SessionAssignment[]) => {
    const errorsInfo = assignments.reduce<{ [key: string]: string[] }>(
      (error, { blockRange, intervals }) => {
        const format = (time: string) => {
          const [hour, min] = time.split(":");
          return `${hour}:${min}`;
        };

        const day = daysTranslations[blockRange?.start?.day];
        const startTime = format(blockRange?.start?.startingTime);
        const endTime = format(blockRange?.end?.endingTime);
        const time = `${startTime} - ${endTime}`;
        const dayAndTime = `${day}, ${time}`;

        const weeks = intervals.map((week: Interval) => week.name);
        error[dayAndTime] = dayAndTime in error ? [...error[dayAndTime], ...weeks] : [...weeks];

        return error;
      },
      {},
    );
    return Object.entries(errorsInfo).map(([date, weeks]) => [date, uniq(weeks).join(", ")]);
  };

  if (errors && errors.length) {
    const errorsBlock = errors.map((error, index) => {
      switch (error.__typename) {
        case "InvalidDate":
          return (
            <p className={cx(css.boxError_title, "col_12")} key={index}>
              El formato de las fechas son icorrectos.
            </p>
          );
        case "InvalidInput":
          return (
            <p className={cx(css.boxError_title, "col_12")} key={index}>
              {error.code === "INTERVAL_NOT_FOUND"
                ? "La fecha esta fuera del rango configurado por la universidad "
                : ""}
              {error.code === "INVALID_CLASSROOM" ? "La sala no existe." : ""}
            </p>
          );

        case "ClassroomClash":
          const classroomClashErrors = flowRight(
            getErrorsFromAssignments,
            getAssignmentsFromErrors,
          )(errors);

          return (
            <div>
              <p className={cx(css.boxError_title, "col_12")} key={index}>
                Existe un choque de salas.
              </p>
              <br />
              {classroomClashErrors.length &&
                classroomClashErrors.map(([date, weeks], idx: number) => {
                  return (
                    <p key={idx}>
                      <strong>Día: </strong> {date} <strong> Semanas: </strong> {weeks}
                    </p>
                  );
                })}
            </div>
          );
        default:
          return null;
      }
    });

    return (
      <CardState title="Error" typeCard="error" className={cx(css.boxError, "col_8", "col_md_12")}>
        <p className={cx(css.boxError_text)}>{errorsBlock}</p>
        <a
          href="#"
          className={cx(css.boxError_link)}
          onClick={() => {
            dispatch({ type: Types.ClearRequest, payload: null });
            dispatch({ type: TypesSearch.ClearSearch, payload: null });
            history.push(routePath);
          }}
        >
          Por favor busque una hora o sala diferente
        </a>
      </CardState>
    );
  }

  return <></>;
};

export default Errors;
