import gql from "graphql-tag";

export const UPDATE_EVENT_MUTATION = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $input: EditSessionInput!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editSession(input: $input) {
        commited
        skippedValidations
        validationErrors {
          ... on ExternalGroupValidation {
            allowed
            rejected_groups
            __typename
          }
          ... on StudentClash {
            __typename
            count
            students {
              id
              code
            }
          }
          ... on InvalidBlock {
            __typename
            isValidStartTime
            isValidEndTime
          }
          ... on BookingClassroomClash {
            __typename
            interval {
              name
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            block {
              startingTime
              endingTime
            }
            sessions {
              id
              section {
                id
              }
            }
            classroom {
              id
              code
            }
          }
          ... on ClassroomClash {
            __typename
            interval {
              id
              name
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            block {
              id
              startingTime
              endingTime
            }
            sessions {
              id
            }
            classroom {
              id
              code
            }
          }
          ... on BookingClassroomClash {
            __typename
            interval {
              name
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            block {
              startingTime
              endingTime
            }
            sessions {
              id
              section {
                id
              }
            }
            classroom {
              id
              code
            }
          }
          ... on InstructorClash {
            __typename
            block {
              id
              startingTime
              endingTime
            }
            interval {
              id
              name
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            sessions {
              id
            }
            instructor {
              id
              code
            }
            block {
              id
              startingTime
              endingTime
            }
          }
          ... on LinkClash {
            __typename
            block {
              id
              startingTime
              endingTime
            }
            interval {
              id
              name
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            sessions {
              id
            }
            link {
              id
              vacancies
            }
          }
          ... on PackageClash {
            __typename
            block {
              id
              startingTime
              endingTime
            }
            interval {
              id
              startingDate
              endingDate
              termParts {
                id
                code
                name
              }
            }
            sessions {
              id
            }
            package {
              id
              code
              index
            }
          }
          ... on InvalidInstructorTravel {
            __typename
            instructor {
              id
              name
            }
            withPrevious {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
            withFollowing {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
          }
          ... on InvalidPackageTravel {
            __typename
            package {
              id
              code
            }
            withPrevious {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
            withFollowing {
              from {
                id
                vacancies
              }
              to {
                id
                vacancies
              }
              blocksBetween
              blocksRequired
            }
          }
          ... on InvalidBlockForInstructor {
            instructor {
              code
            }
          }

          ... on InstructorExceededWeeklyBlocks {
            instructor {
              code
            }
            assignableBlocks
            exceededBlocks
          }
        }
        session {
          id
          vacancies
          assignment {
            day
            blockRange {
              start {
                id
                startingTime
              }
              end {
                id
                endingTime
              }
            }
          }
          scheduleEvent {
            colorIndex
            id
            start
            end
          }
        }
      }
    }
  }
`;
