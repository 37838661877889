import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import { SelectPagination } from "@foris/avocado-ui";
import cx from "classnames";
import { Link, LinkPage } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { IOption } from "@common/components/Select/Select";
import { LINKS_QUERY } from "@common/layout/ContextSearch/contextsearch.queries";
import css from "./linkSelector.module.scss";

interface LinkOption {
  label: string;
  value: string;
}

const getOriginalTermOption = (link: Link): LinkOption => {
  if (!link?.code || !link?.id) {
    return {
      label: "",
      value: "",
    };
  }

  return {
    label: (
      <div className={css.linkOptionLabel}>
        <p className={css.linkOptionLabel_mainLabel}>
          CL: <b>{link?.code}</b>
        </p>

        <p className={css.linkOptionLabel_secondaryLabel}>
          AS: <b>{link?.course?.name}</b> | SE:{" "}
          <b>{link?.course?.curriculum?.program?.campus?.code}</b> | PE:{" "}
          <b>{link?.bundle?.term?.name}</b>
        </p>
      </div>
    ) as any,
    value: link?.id,
  };
};

const getTLinkOption = (link: Link | LinkOption): LinkOption => {
  const linkOption = link as LinkOption;

  if (linkOption?.value && linkOption?.label) return linkOption;

  return getOriginalTermOption(link as Link);
};

interface LinkSelectorProps {
  className?: string;
  isClearable?: boolean;
  label?: string;
  isDisabled?: boolean;
  linksToAvoid?: string[];
  sendValues?: (payload: { link: string | null }) => void;
  onSelectLink?: (link?: Link) => void;
}

const savedOptions: { [key: string]: Link } = {};

const LinkSelector: React.FC<LinkSelectorProps> = props => {
  const {
    className = "",
    isClearable = true,
    label = "Liga",
    sendValues,
    onSelectLink,
    isDisabled = false,
    linksToAvoid = [],
  } = props;
  const client = useApolloClient();
  const { scenario, origin }: IParams = useParams();
  const [valueLink, setValueLink] = useState<LinkOption | null>(null);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);

  const queryOptions = (searchTerm = "", page = 1) => {
    const pageSize = 20;

    return {
      query: LINKS_QUERY,
      variables: {
        scenarioId: scenario,
        originId: origin,
        filter: {
          page,
          size: pageSize,
          searchTerm,
        },
      },
    };
  };

  const getTermOptions = async (searchTerm = "", page = 1) => {
    try {
      const query = queryOptions(searchTerm, page);
      const { data } = await client.query(query);

      const linksPage = data?.cube?.links as LinkPage;
      const links = linksPage?.items;

      const options = (links ?? [])
        .filter(link => !linksToAvoid?.includes(link?.id))
        .map(link => {
          if (!(link.id in savedOptions)) {
            savedOptions[link.id] = link;
          }

          return getTLinkOption(link);
        });

      return {
        options: [...options],
        pageInfo: linksPage?.pageInfo,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const loadLinks = async () => {
    const hasSameTerm = prevSearchTerm === searchTerm;
    const newSearchPage = hasSameTerm ? page + 1 : 1;

    setPrevSearchTerm(searchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await getTermOptions(searchTerm, newSearchPage);

    return {
      options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  const handleOnChange = (linkOption: IOption) => {
    setValueLink(linkOption || null);
    sendValues?.({
      link: linkOption?.value || null,
    });

    onSelectLink?.(savedOptions?.[linkOption?.value]);
  };

  return (
    <SelectPagination
      className={cx(className, css.linkSelector)}
      onInputChange={setSearchTerm}
      label={label}
      value={valueLink}
      debounceTimeout={500}
      placeholder="Seleccionar Liga"
      isClearable={isClearable}
      onChange={handleOnChange}
      loadOptions={loadLinks}
      isDisabled={isDisabled}
    />
  );
};

export default LinkSelector;
