import React, { useContext, useState, useCallback, useRef, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { Icon, Tooltip } from "@foris/avocado-ui";
import cx from "classnames";
import Groups from "../Groups/Groups";
import Instructor from "../Instructor/Instructor";
import LinkStructure from "./LinkStructure";
import AddGroup from "../Groups/AddGroup";
import {
  IGroupValue,
  IContextVacancies,
  ContextVacancies,
  ILeague,
  IBundleTerm,
} from "../contextVacancies/ContextVacancies";
import { VacanciesType } from "../Vacancies";
import { IParams } from "../../../models/IParams";
import { IObjValidationError } from "../utils";
import { ContextApp, IContextApp } from "@config/Context/contextApp";
import css from "./links.module.scss";
import GroupMigration from "../GroupMigration";

export interface ICardVacanciesProps {
  data: Array<ILeague>;
  bundleTerm?: IBundleTerm;
  activeEdit: boolean;
  validationArray?: Array<IObjValidationError>;
  setValidationArray?: any;
  setDisabledConfirm: any;
  type: VacanciesType;
  nameGroup?: string;
}

const Links: React.FC<ICardVacanciesProps> = ({ data, ...props }: ICardVacanciesProps) => {
  const {
    activeEdit,
    validationArray,
    setValidationArray,
    setDisabledConfirm,
    type,
    nameGroup,
    bundleTerm,
  } = props;
  const { origin, scenario, workspace }: IParams = useParams();
  const contextApp: IContextApp = useContext(ContextApp);
  const context: IContextVacancies = useContext(ContextVacancies);
  const [responsibilityChange, setResponsibilityChange] = useState<Array<Array<IGroupValue>>>([]);
  const refContext = useRef(context);
  const [openGroup, setOpenGroup] = useState(false);
  const [groupToMigrate, setGroupToMigrate] = useState<string | number | null>(null);
  const [linkId, setLinkId] = useState(null);
  const [subTitle, setSubtitle] = useState(null);
  const userCanEdit =
    contextApp?.user?.permissions?.update && contextApp?.user?.abilities?.can_edit_assignment;

  const setValuesChange = useCallback(
    (value: Array<IGroupValue>, index: number) => {
      const arrayData: Array<Array<IGroupValue>> = [];
      const countContext = refContext.current.changeValues
        ? refContext.current.changeValues.length
        : 0;
      if (value) {
        if (data.length > countContext) {
          arrayData.push(value);
          refContext.current.setChangeValues(arrayData);
        } else {
          const cloneContext = [...refContext.current.changeValues];
          cloneContext.splice(index, 1);
          cloneContext.splice(index, 0, value);
          refContext.current.setChangeValues(cloneContext);
        }
      }
    },
    [data, refContext],
  );

  const setValuesResponsability = (value: Array<IGroupValue>, index: number) => {
    const cloneResponsability = [...responsibilityChange];
    if (cloneResponsability.length > 0) {
      cloneResponsability.splice(index, 1);
      cloneResponsability.splice(index, 0, value);
    } else {
      cloneResponsability.push(value);
    }
    setResponsibilityChange(cloneResponsability);
  };

  const handleOpenGroup = (link: string) => {
    setOpenGroup(true);
    setLinkId(link);
  };

  useEffect(() => {
    context.setInstructorValues(responsibilityChange);
  }, [responsibilityChange]);

  const itemCode = (item: ILeague) => {
    return (
      <Tooltip className={css.headerCard_code__underline} label="Código generado por sistema">
        {item?.clientCode ? <b>{item?.code}</b> : `(${item.code})`}
      </Tooltip>
    );
  };

  const itemClientCode = (item: ILeague) => {
    return (
      <Tooltip className={css.headerCard_code__underline} label="Código configurado por usuario">
        (cod.Usuario <b>{item?.clientCode}</b>)
      </Tooltip>
    );
  };

  const isModalVisible = openGroup || !!groupToMigrate;

  return (
    <>
      {!isModalVisible &&
        data &&
        data.map((item: ILeague, index: number) => {
          return (
            <article className={cx(css.cntCard, "container-row")} key={index}>
              <header
                className={cx(css.headerCard, "container-row row_align--center")}
                style={{ background: item.color }}
              >
                <div className={css.headerCard_circle} />
                <h3 className={css.headerCard_title}>Liga {`${item.codeName}`}</h3>
                <Link
                  to={`/scheduler/editor/link/${workspace}/${scenario}/${origin}/${item?.linkId}`}
                  className={css.headerCard_code}
                >
                  {itemCode(item)} {!!item?.clientCode && itemClientCode(item)}
                </Link>
                {contextApp?.user?.abilities?.can_edit_links_and_sections && !item?.clientCode && (
                  <Link
                    to={`/editor/link/client-code/${workspace}/${scenario}/${origin}/${item?.linkId}`}
                    className={css.headerCard_codeManagement}
                  >
                    (<Icon icon="edit" size={12} /> Añadir código)
                  </Link>
                )}
                {userCanEdit && (
                  <Link
                    to={`/editor/form-edit/${workspace}/${scenario}/${origin}/${item?.linkId}`}
                    className={css.headerCard_linkForm}
                  >
                    <span className={css.text}>Editar horarios</span>
                    <Icon icon="external-link" size={14} className={css.icon} />
                  </Link>
                )}

                {userCanEdit && !!item?.course?.generatesPackages && (
                  <Link
                    to={`/editor/packages-edit/${workspace}/${scenario}/${origin}/${item?.linkId}`}
                    className={css.headerCard_linkForm}
                  >
                    <span className={css.text}>Editar paquetes</span>
                    <Icon icon="external-link" size={14} className={css.icon} />
                  </Link>
                )}
              </header>
              {type === VacanciesType.vacancies && (
                <Groups
                  validationArray={validationArray}
                  item={item}
                  index={index}
                  linkId={item.linkId}
                  bundleTerm={bundleTerm}
                  setValues={setValuesChange}
                  activeEdit={activeEdit}
                  openGroup={() => {
                    handleOpenGroup(item.linkId);
                    setSubtitle(item.code);
                  }}
                  onMigrateGroup={groupId => {
                    setLinkId(item.linkId);
                    setGroupToMigrate(groupId);
                  }}
                  setValidationArray={setValidationArray}
                  setDisabledConfirm={setDisabledConfirm}
                />
              )}
              {type === VacanciesType.instructor && (
                <Instructor
                  item={item}
                  index={index}
                  linkId={item.linkId}
                  setValues={setValuesResponsability}
                  activeEdit={activeEdit}
                />
              )}
              <LinkStructure item={item} index={index} />
            </article>
          );
        })}
      {openGroup && (
        <AddGroup
          onClose={() => setOpenGroup(false)}
          linkId={linkId}
          headerData={{ subTitle: subTitle, title: nameGroup }}
        />
      )}

      {!!groupToMigrate && (
        <GroupMigration
          linkId={linkId}
          groupName={nameGroup}
          groupId={groupToMigrate}
          onCancel={() => setGroupToMigrate(null)}
        />
      )}
    </>
  );
};

export default Links;
