import React, { useState, useContext, useCallback } from "react";
import { last, view, lensPath, map, prop, join, pipe, isEmpty, has, includes } from "ramda";
import { ContextEDH } from "@context/ContextEDH";
import { useParams, Link, useHistory, useLocation } from "react-router-dom";
import { Button, Icon, DonutLegend } from "@foris/avocado-ui";
import { IParams } from "@models/IParams";
import * as mouseflow from "@utils/mouseflow";
import cx from "classnames";
import { Context } from "../../context/GroupsManagerContext";
import Donut from "../../components/Donut/Donut";
import { Types as NavigationTypes } from "../../context/navigation.reducer";
import { Types as FilterSelectorsTypes } from "../../context/filtersSelectors.reducer";
import { Types as FilterTypes } from "../../context/filters.reducer";
import ScheduleSummary from "./ScheduleSummary";
import { AdaptedCourse, AdaptedGroup } from "../../models";
import capacityCalculationReasonText from "../../utils/capacityCalculationReasonText";
import donut, { getDonutRows, getTotal, getUsed, getWaiting } from "../../utils/donut";
import css from "./groupDetail.module.scss";
import { Package } from "@models/ISchema";
import { FiltersType } from "../Filters/Filters";

const COURSE_TYPE_TAG = "itesm.mx/course_type_name";

interface Props {
  capacitiesSumUp: number;
  displayCapacitiesError: boolean;
}

const GroupDetail: React.FC<Props> = ({ capacitiesSumUp, displayCapacitiesError }) => {
  const { state: outerState } = useContext(ContextEDH);
  const { state, dispatch } = useContext(Context);
  const location = useLocation();
  const history = useHistory();
  const [displayInfo, setDisplayInfo] = useState(false);
  const { origin, scenario, workspace }: IParams = useParams();
  const contextUrl = `${workspace}/${scenario}/${origin}`;

  const departments = (): string => {
    const group = state?.data?.group;
    if (!group) return "--";

    const departments = view(lensPath(["subject", "academicUnits", "departments"]), group) ?? [];
    if (isEmpty(departments)) return "--";
    return pipe(map(prop("name")), join(", "))(departments);
  };

  const schools = (): string => {
    const group = state?.data?.group;
    if (!group) return "--";

    const schools = view(lensPath(["subject", "academicUnits", "schools"]), group) ?? [];
    if (isEmpty(schools)) return "--";
    return pipe(map(prop("name")), join(", "))(schools);
  };

  const efficiency = (): string => {
    const group = state?.data?.group;
    const min = group?.groupCapacity?.configMinCapacity ?? 0;
    const max = group?.groupCapacity?.configMaxCapacity ?? 0;
    return !min || !max ? "N/A" : `${min} - ${max}`;
  };

  const groupLabels = (): string => {
    const labels = state?.data?.group?.labels;
    return pipe(map(prop("code")), join(", "))(labels ?? []);
  };

  const courseTypeName = (): string => {
    const course = state?.data?.group?.course ?? ({} as AdaptedCourse);
    if (!has(COURSE_TYPE_TAG, course?.tags ?? {})) return "--";
    return course?.tags[COURSE_TYPE_TAG] as string;
  };

  const getGroupsStats = useCallback(() => {
    const groups = state?.data?.groups || [];
    if (!groups?.length) return [];

    const stats = {
      total: 0,
      used: 0,
      waiting: 0,
    };

    groups.forEach(gp => {
      const total = getTotal(gp) ?? 0;
      const used = getUsed(gp) ?? 0;
      const waiting = getWaiting(gp) ?? 0;

      stats.total += total;
      stats.used += used;
      stats.waiting += waiting;
    });

    return getDonutRows(stats);
  }, [state?.data?.group, state?.data?.groups]);

  const handlePackageClick = (item: Package) => {
    dispatch({ type: FilterTypes.CleanFilters });
    dispatch({ type: FilterTypes.SelectPackage, payload: item?.id });
    dispatch({
      type: FilterSelectorsTypes.SetSelectedPackage,
      payload: {
        id: item?.id,
        value: item?.id,
        label: item?.code,
        self: item,
      },
    });
    dispatch({
      type: FilterSelectorsTypes.SetFilterToDisplay,
      payload: FiltersType.PACKAGE,
    });

    history.push(`/editor/groups-manager/${contextUrl}`);
  };

  return (
    <div className={css.card}>
      <header className={css.card_header}>
        <div className={css.card_header_title}>
          <Link
            style={{ color: "black", top: "0.5rem" }}
            to={{
              pathname:
                last(state?.navigation?.locationStack ?? [])?.view ??
                `/editor/groups-manager/${contextUrl}`,
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              dispatch({ type: NavigationTypes.SetForceFetching, payload: true });

              const url =
                last(state?.navigation?.locationStack ?? [])?.view ??
                `/editor/groups-manager/${contextUrl}`;

              // handle mouseflow
              if (includes("groups-manager/subgroups", url)) {
                mouseflow.actionTag(
                  "action_groups_manager_subgroups",
                  outerState?.base?.isMouseflowEnabled,
                );
              } else {
                mouseflow.actionTag("action_groups_manager", outerState?.base?.isMouseflowEnabled);
              }

              history.push(url);
            }}
          >
            <Icon icon="chevron-left" size={25} />
            <strong className={css.card_header_title_text}>Grupo {state?.data?.group?.code}</strong>
          </Link>
          <Link
            to={{
              pathname: `/editor/groups-manager/change_history/${contextUrl}/${state?.data?.group?.id}`,
            }}
            onClick={(e: React.MouseEvent) => {
              e.preventDefault();
              const pathname = `/editor/groups-manager/change_history/${contextUrl}/${state?.data?.group?.id}`;
              dispatch({
                type: NavigationTypes.AddToLocationStack,
                payload: {
                  view: location?.pathname,
                  page: state?.data?.groupsPageInfo?.page ?? 1,
                },
              });
              history.push(pathname);
            }}
            className={css.card_header_title_link}
          >
            <Icon icon="external-link" size={25} /> Historial de cambios
          </Link>
        </div>
        <div className={css.card_header_info}>
          <DonutLegend className={css.tableCard_info__donut} data={donut({} as AdaptedGroup)} />
        </div>
      </header>

      {/* Group detail */}
      <section className={cx(css.card_section, displayInfo ? css.bottomSeparator : "")}>
        <div className={css.detail_container}>
          <ul className={cx("container-row", css.detail_row)}>
            {/* CRN */}
            <li className={cx(css.detail_item, css.detail_item_crn)}>
              <h3 className={css.detail_item_title}>CRN</h3>
              <Link
                to={`/editor/packages-edit/${contextUrl}/${state?.data?.group?.link?.id}`}
                className={css.detail_item__link}
              >
                {state?.data?.group?.clientCode}
              </Link>
            </li>

            {/* Campus */}
            <li className={cx(css.detail_item, css.detail_item_campus)}>
              <h3 className={css.detail_item_title}>Campus</h3>
              <p className={css.detail_item_value}>{state?.data?.group?.campus?.code}</p>
            </li>

            {/* Course */}
            <li className={cx(css.detail_item, css.detail_item_course)}>
              <h3 className={css.detail_item_title}>Asignatura</h3>
              <p className={css.detail_item_value}>
                {state?.data?.group?.course?.code} {state?.data?.group?.course?.name}
              </p>
            </li>

            {/* Department */}
            <li className={cx(css.detail_item, css.detail_item_department)}>
              <h3 className={css.detail_item_title}>Departamento</h3>
              <p className={css.detail_item_value}>{departments()}</p>
            </li>

            {/* School */}
            <li className={cx(css.detail_item, css.detail_item_school)}>
              <h3 className={css.detail_item_title}>Escuela</h3>
              <p className={css.detail_item_value}>{schools()}</p>
            </li>

            {/* State */}
            <li className={cx(css.detail_item, css.detail_item_state)}>
              <h3 className={css.detail_item_title}>Estado</h3>
              <p className={css.detail_item_value}>
                {state?.data?.group?.isActive ? "Activo" : "Inactivo"}
              </p>
            </li>

            <li className={cx(css.detail_item, css.detail_item_cupos)}>
              <h3 className={css.detail_item_title}>Cupos</h3>
              <div className={css.detail_item_cupos_value_container}>
                <p className={css.detail_item_value}>{capacitiesSumUp}</p>
                <div
                  className={cx(
                    css.detail_item_cupos_error,
                    displayCapacitiesError ? "" : css.hide,
                  )}
                >
                  <p className={css.detail_item_cupos_error_tooltip}>Cupo mayor que la capacidad</p>
                  <Icon className={css.detail_item_cupos_error_icon} icon="circle-full-error" />
                </div>
              </div>
            </li>

            {/* Donut */}
            <li className={cx(css.detail_item, css.detail_item_donut)}>
              <Donut group={state?.data?.group} rows={getGroupsStats()} />
            </li>
          </ul>

          <ul className={cx("container-row", css.detail_row)}>
            {/* Efficiency */}
            <li
              className={cx(
                css.detail_item,
                css.detail_item_efficiency,
                displayInfo ? "" : css.hide,
              )}
            >
              <h3 className={css.detail_item_title}>Eficiencia</h3>
              <p className={css.detail_item_value}>{efficiency()}</p>
            </li>

            {/* Capacity */}
            <li
              className={cx(css.detail_item, css.detail_item_capacity, displayInfo ? "" : css.hide)}
            >
              <h3 className={css.detail_item_title}>Capacidad</h3>
              <div className={css.detail_item_cupos_value_container}>
                <p className={css.detail_item_value}>
                  {state?.data?.group?.groupCapacity?.calculatedCapacity}
                </p>
                <div className={css.detail_item_cupos_error}>
                  <p className={css.detail_item_cupos_error_tooltip}>
                    {capacityCalculationReasonText(state?.data?.group?.groupCapacity)}
                  </p>
                  <Icon className={css.detail_item_cupos_info_icon} icon="circle-alert" />
                </div>
              </div>
            </li>

            {/* Visibility */}
            <li
              className={cx(
                css.detail_item,
                css.detail_item_visibility,
                displayInfo ? "" : css.hide,
              )}
            >
              <h3 className={css.detail_item_title}>Visibilidad</h3>
              <p className={css.detail_item_value}>
                {state?.data?.group?.visibleForEnrollment ? "Visible" : "No visible"}
              </p>
            </li>
            {!!state?.data?.group?.link?.packages?.length && (
              <li
                className={cx(
                  css.detail_item,
                  css.detail_item_visibility,
                  displayInfo ? "" : css.hide,
                )}
              >
                <h3 className={css.detail_item_title}>Paquetes</h3>
                <div className={css.detail_item_list}>
                  {state?.data?.group?.link?.packages?.map(item => (
                    <div
                      key={item?.id}
                      className={css.itemLink}
                      onClick={() => handlePackageClick(item)}
                    >
                      {item?.code}
                      {item?.name ? ` - ${item?.name}` : ""}
                    </div>
                  ))}
                </div>
              </li>
            )}
          </ul>
        </div>
      </section>

      {/* Group schedules */}
      {!isEmpty(state?.data?.group?.scheduleSummary ?? []) && (
        <section
          className={cx(
            css.card_section,
            displayInfo ? css.bottomSeparator : "",
            displayInfo ? "" : css.hide,
          )}
        >
          <ul className={css.schedules_container}>
            <ScheduleSummary
              schedules={state?.data?.group?.scheduleSummary}
              primaryInstructorId={state?.data?.group?.primaryInstructor?.id}
            />
          </ul>
        </section>
      )}

      {/* Group Term */}
      <section className={cx(css.card_section, css.group_info, displayInfo ? "" : css.hide)}>
        <p>
          <strong>Periodo</strong>: {state?.data?.group?.term?.name}
        </p>
        <p>
          <strong>Atributos del grupo</strong>: {groupLabels()}
        </p>
        <p>
          <strong>Tipo de UF</strong>: {courseTypeName()}
        </p>
      </section>

      {/* Hide Button */}
      <section className={cx(css.card_section, css.button_container, css.topSeparator)}>
        <Button
          className={css.button}
          disabled={false}
          outline={true}
          typeButton={"white"}
          onClick={() => setDisplayInfo(!displayInfo)}
        >
          {displayInfo ? "Ocultar información" : "Mostrar información adicional"}
        </Button>
      </section>
    </div>
  );
};

export default GroupDetail;
