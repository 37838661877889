import gql from "graphql-tag";

export const BUNDLES_QUERY_ID = gql`
  query bundlesId($scenarioId: ID!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      bundles {
        items {
          id
          code
          label
          name
        }
      }
    }
  }
`;

export const BUNDLES_QUERY = gql`
  query bundles($scenarioId: ID!, $originId: ID!, $bundleId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      bundle(id: $bundleId) {
        id
        code
        label
        name
        capacity
        term {
          id
          code
        }
        links {
          id
          code
          label
          course {
            id
            code
            canEdit
            generatesPackages
            curriculum {
              id
              code
              program {
                id
                label
                name
                code
                campus {
                  id
                  code
                  name
                  __typename
                }
              }
            }
          }
          totalBlocks
          blocksWithoutInstructorAssigment
          instructorResponsability {
            id
            value
            totalBlocks
            components
            teachingHoursPercentage
            isPrimary
            paymentPercentage
            instructor {
              id
              code
              name
            }
          }
          groups {
            id
            code
            label
            name
            capacity
            clientCode
            visibleForEnrollment
            isActive
            curriculum {
              id
              code
            }
            course {
              id
              code
              canEdit
              curriculum {
                id
                code
                program {
                  id
                  label
                  name
                  code
                }
              }
            }
            enrollmentStats {
              id
              capacity
              reservedCapacity
              optCapacity
              maxCapacity
              minCapacity
              usedCapacity
              enrollmentsCount
              waitingListsCount
              bookingsCount
              instentsCount
              viewsCount
            }
          }
          sections {
            id
            component {
              id
              code
            }
            vacancies
            linksByBundles(bundleId: $bundleId) {
              id
              code
              vacancies
            }
          }
        }
      }
    }
  }
`;

export const BUNDLES_MUTATION = gql`
  mutation Editar($scenarioId: ID!, $originId: ID!, $bundleInput: EditBundleInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editBundle(input: $bundleInput) {
        commited
        validationErrors {
          ... on InvalidLinkChanges {
            link {
              id
              code
            }
            sectionChanges {
              section {
                id
                courseComponent {
                  id
                  academicStandard
                }
              }
              validationErrors {
                ... on InvalidComponentSize {
                  reason
                }
              }
            }
            groupChanges {
              group {
                id
                code
                label
                enrollmentStats {
                  id
                  enrollmentsCount
                }
              }
              validationErrors {
                ... on InvalidGroupEnrollment {
                  reason
                }
                ... on ExternalGroupValidation {
                  allowed
                  rejected_groups
                  reason
                  __typename
                }
              }
            }
            sessionChanges {
              session {
                id
                vacancies
                section {
                  id
                }
              }
              validationErrors {
                __typename
                ... on InvalidClassroomCapacity {
                  classroom {
                    id
                    capacity
                  }
                  reason
                }
              }
            }
          }
        }
      }
      __typename
    }
  }
`;
