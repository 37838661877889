import gql from "graphql-tag";

export const updateGroupText = gql`
  mutation updateGroupText($scenarioId: ID!, $originId: ID!, $input: EditGroupTextsInput) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      editGroupTexts(input: $input) {
        group {
          id
          code
          label
          name
          capacity
          type
          text
          clientCode
          visibleForEnrollment
          totalBlocks
          course {
            code
            name
            buildings {
              id
              code
              name
              __typename
              campus {
                id
                code
                name
                __typename
              }
            }
          }
          customFields {
            id
            value
            code
            __typename
          }
          links {
            id
            vacancies
            code
            bundle {
              id
              code
              label
              name
              capacity
              __typename
            }
            __typename
          }
          enrollmentStats {
            id
            enrollmentsCount
            waitingListsCount
            __typename
          }
          labels {
            id
            code
            name
            category {
              id
              code
              name
              __typename
            }
            __typename
          }
          __typename
        }
      }
      __typename
    }
  }
`;
