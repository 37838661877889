import React, { useContext, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Icon } from "@foris/avocado-ui";
import * as mouseflow from "@utils/mouseflow";
import { ContextEDH } from "@context/ContextEDH";
import { find, propEq } from "ramda";
import { IParams } from "../../../models/IParams";
import "./menu.scss";

export interface IChange {
  isOpen: boolean;
}

const darwinLogin = process.env.REACT_APP_DARWINED_URL;

const MenuHead = () => {
  const history = useHistory();
  const { state } = useContext(ContextEDH);
  const { origin, scenario, workspace }: IParams = useParams();
  const [open, setOpen] = useState(false);
  const contextUrl = `${workspace}/${scenario}/${origin}`;
  const base = state?.base?.base;
  const accessToBookingUser =
    base?.modules?.find(item => item.code === "bookings").accesible || false;
  const handleStateChange = (state: IChange) => {
    setOpen(state.isOpen);
  };
  const userCanCreate = base?.user?.permissions?.create;
  const userCanEdit = base?.user?.permissions?.update;
  const userCanEditLinkSection = base?.user?.abilities?.can_edit_links_and_sections;
  const userCanAccessToGroupsManager = base?.user?.abilities?.can_access_group_manager;
  const userCanDeleteSessionsInHolidays = base?.user?.abilities?.can_delete_sessions_in_holidays;
  const sianLink = find(propEq("code", "SIAN"), base?.customUrls ?? []);

  const closeMenu = () => setOpen(false);

  const groupsAndPackageReportUrl = !!darwinLogin
    ? `${darwinLogin}groups_manager/group_packages/${origin}?scenario_id=${scenario}`
    : "";

  return (
    <Menu
      onStateChange={(state: IChange) => handleStateChange(state)}
      isOpen={open}
      className="bm-menu"
      pageWrapId="header"
      outerContainerId="root"
      customBurgerIcon={<Icon icon="menu" size={18} className="iconMenu" />}
    >
      <Link to={`/home/${contextUrl}`}>
        <div className="menu-item" onClick={closeMenu}>
          Inicio
        </div>
      </Link>
      <Link to={`/editor/advanceSearch/${contextUrl}`}>
        <div className="menu-item" onClick={closeMenu}>
          Búsqueda avanzada
        </div>
      </Link>
      {(userCanCreate || userCanEdit) && accessToBookingUser && (
        <Link to={`/booking/list/${contextUrl}`}>
          <div className="menu-item" onClick={closeMenu}>
            Reservas
          </div>
        </Link>
      )}
      {userCanCreate && userCanEditLinkSection && (
        <Link to={`/editor/create-section/${contextUrl}`}>
          <div className="menu-item" onClick={closeMenu}>
            Crear Liga
          </div>
        </Link>
      )}
      {userCanCreate && (
        <Link to={`/editor/create-package/${contextUrl}`}>
          <div className="menu-item" onClick={closeMenu}>
            Crear Paquete
          </div>
        </Link>
      )}
      {userCanAccessToGroupsManager && sianLink?.url && (
        <a className="menu-item" href={sianLink?.url} target="_blank" rel="noopener noreferrer">
          Solicitar Cupo (SIAN)
        </a>
      )}
      {userCanAccessToGroupsManager && (
        <Link
          to={`/editor/groups-manager/${contextUrl}`}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            mouseflow.actionTag("action_groups_manager", state?.base?.isMouseflowEnabled);
            history.push(`/editor/groups-manager/${contextUrl}`);
          }}
        >
          <div className="menu-item" onClick={closeMenu}>
            Administración de Grupos
          </div>
        </Link>
      )}
      {!!userCanDeleteSessionsInHolidays && (
        <Link to={`/editor/session/holidays/${contextUrl}`}>
          <div className="menu-item" onClick={closeMenu}>
            Eliminar sesiones en Festivos
          </div>
        </Link>
      )}
      {userCanAccessToGroupsManager && (
        <Link
          className="topBorder"
          to={`/editor/groups-manager/change_history/${contextUrl}`}
          onClick={(e: React.MouseEvent) => {
            e.preventDefault();
            mouseflow.actionTag(
              "action_groups_manager_main_change_history",
              state?.base?.isMouseflowEnabled,
            );
            history.push(`/editor/groups-manager/change_history/${contextUrl}`);
          }}
        >
          <div className="menu-item" onClick={closeMenu}>
            Historial de cambios
          </div>
        </Link>
      )}
      {!!groupsAndPackageReportUrl && (
        <Link to={`/report/packages-and-groups/${contextUrl}`}>
          <div className="menu-item" onClick={closeMenu}>
            Reporte de Paquetes y Grupos
          </div>
        </Link>
      )}

      <footer className="footer_menu container-row">
        <Link
          to={darwinLogin ?? "/"}
          className="footer_menu-text col_12"
          onClick={() => {
            localStorage.clear();
          }}
        >
          <Icon className="log_out" icon="log-out" size={16} />
          Cerrar Sesión
        </Link>
      </footer>
    </Menu>
  );
};

export default MenuHead;
