import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { Select } from "@foris/avocado-ui";
import { modalitiesQuery } from "../graphql/modality.queries";
import { Modality, DataQuery } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorModality, IOption } from "./ISelectors";
import css from "./selectors.module.scss";

const ModalitySelect: React.FC<ISelectorModality> = (props: ISelectorModality) => {
  const client = useApolloClient();
  const { onCallback, department, label, className, shouldHasDepartment = true } = props;
  const { scenario }: IParams = useParams();
  const [optionsModality, setOptionsModality] = useState([]);
  const [valueModality, setValueModality] = useState(null);
  const [prevDepartment, setPrevDepartment] = useState(department);
  const [disabled, setDisabled] = useState(false);

  const variablesModality = {
    query: modalitiesQuery,
    variables: {
      scenarioId: scenario,
    },
  };

  const setModalityOptions = useCallback(async data => {
    if (data && data.data) {
      const dataQuery: DataQuery = data.data.data;
      const optModalities = [];
      if (dataQuery.modalities.items.length > 0) {
        dataQuery.modalities.items.forEach((modality: Modality) => {
          optModalities.push({ label: modality.name, value: modality.id });
        });
      }
      setOptionsModality(optModalities);
    }
  }, []);

  const getModalityOptions = useCallback(async () => {
    try {
      const data = await client.query(variablesModality);
      await setModalityOptions(data);
    } catch (error) {
      console.log(error);
    }
  }, [client, variablesModality, setModalityOptions]);

  const getCacheModalityOptions = useCallback(async () => {
    try {
      const data = await client.readQuery(variablesModality);
      await setModalityOptions(data);
    } catch (error) {
      getModalityOptions();
    }
  }, [client, variablesModality, setModalityOptions, getModalityOptions]);

  useEffect(() => {
    if (shouldHasDepartment && department && prevDepartment !== department && disabled) {
      getCacheModalityOptions();
      setPrevDepartment(department);
      setDisabled(false);
    }
    if (shouldHasDepartment && !department) {
      setPrevDepartment(null);
      setValueModality("");
      setDisabled(true);
    }
  }, [disabled, department, prevDepartment, getCacheModalityOptions]);

  useEffect(() => {
    if (!shouldHasDepartment) {
      getCacheModalityOptions();
      setDisabled(false);
    }
  }, []);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Modalidad</label>}
      <Select
        options={optionsModality}
        value={valueModality}
        placeholder={label ? "Seleccionar" : "Modalidad"}
        isClearable
        isDisabled={disabled}
        onChange={(value: IOption) => {
          if (value) {
            setValueModality(value);
            onCallback(value);
          } else {
            setValueModality("");
            onCallback(value);
          }
        }}
      />
    </div>
  );
};

export default ModalitySelect;
