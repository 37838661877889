import gql from "graphql-tag";

export const classroomQuery = gql`
  query megaSearch($scenarioId: ID!, $filter: PaginationInput!, $originId: ID!) {
    cube(scenarioId: $scenarioId, originId: $originId) {
      classrooms(filter: $filter) {
        items {
          id
          code
          capacity
          allowedOverflow
          name
          classroomType {
            id
            code
            name
          }
          building {
            id
            code
            name
            campus {
              id
              code
              name
            }
          }
        }
        pageInfo {
          size
          page
          total
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
