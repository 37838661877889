import { ActionMap } from "@models/IContext";

type SwitchPage = {
  form: boolean;
  results: boolean;
  advanceSearchInstructor: boolean;
  advanceSearchClassroom: boolean;
};

export type SwitchPageType = {
  active: SwitchPage;
  loading?: boolean;
};

export enum Types {
  FormPage = "FORM_PAGE",
  ResultsPage = "RESULTS_PAGE",
  AdvanceSearchInstructor = "ADVANCE_SEARCH_INSTRUCTOR_PAGE",
  AdvanceSearchClassroom = "ADVANCE_SEARCH_CLASSROOM_PAGE",
  SetLoading = "SET_LOADING",
}

type SwitchPagePayload = {
  [Types.FormPage]: boolean;
  [Types.ResultsPage]: boolean;
  [Types.AdvanceSearchInstructor]: boolean;
  [Types.AdvanceSearchClassroom]: boolean;
  [Types.SetLoading]: boolean;
};

export type SwitchPageActions = ActionMap<SwitchPagePayload>[keyof ActionMap<SwitchPagePayload>];

export const switchPageReducer = (state: SwitchPageType, action: SwitchPageActions) => {
  switch (action?.type) {
    case Types.FormPage:
      if (action.payload) {
        return {
          active: {
            form: action.payload,
            results: false,
            advanceSearchInstructor: false,
            advanceSearchClassroom: false,
          },
        };
      } else return { ...state };
    case Types.ResultsPage:
      if (action.payload) {
        return {
          active: {
            form: false,
            results: action.payload,
            advanceSearchInstructor: false,
            advanceSearchClassroom: false,
          },
        };
      } else return { ...state };
    case Types.AdvanceSearchInstructor:
      if (action.payload) {
        return {
          active: {
            form: false,
            results: false,
            advanceSearchInstructor: action.payload,
            advanceSearchClassroom: false,
          },
        };
      } else return { ...state };
    case Types.AdvanceSearchClassroom:
      if (action.payload) {
        return {
          active: {
            form: false,
            results: false,
            advanceSearchInstructor: false,
            advanceSearchClassroom: action.payload,
          },
        };
      } else return { ...state };
    case Types.SetLoading:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};
