import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "react-apollo";
import { IntrospectionFragmentMatcher, InMemoryCache } from "apollo-cache-inmemory";
import { onError } from "apollo-link-error";
import * as serviceWorker from "./serviceWorker";
import introspectionQueryResultData from "./fragment-types.json";
import { ContextApp, useContextApp } from "@config/Context/contextApp";
import { BookingContextProvider } from "@modules/booking/context/BookingContext";
import { ContextEDHProvider } from "./context/ContextEDH";
import Auth from "./modules/login/Auth";
import Plausible from "plausible-tracker";
import "@config/i18n";

const darwinLogin = process.env.REACT_APP_DARWINED_URL;

// apollo
const authLink = setContext((_, { headers }) => {
  // return the headers to the context so httpLink can read them

  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");

  return {
    headers: {
      ...headers,
      token: token ? `${token}` : "",
    },
  };
});

const logoutLink = onError(({ networkError }) => {
  if (networkError && networkError["statusCode"] === 401) {
    window.location.href = darwinLogin;
  }
});
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});
const cache = new InMemoryCache({ fragmentMatcher });
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_PATH,
});
const middleware = authLink.concat(logoutLink);
const link = middleware.concat(httpLink);
const client = new ApolloClient({
  cache,
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

let cleanupPlausible = null;

const AppDOM = () => {
  const contextApp = useContextApp();

  useEffect(() => {
    if (process.env.REACT_APP_LOCK_EDIT === "false") {
      contextApp.setLockEdit(false);
    } else {
      contextApp.setLockEdit(true);
    }
  }, [contextApp]);

  useEffect(() => {
    if (contextApp?.user?.id && !cleanupPlausible) {
      const { enableAutoPageviews } = Plausible({
        domain: window.location.origin,
      });

      cleanupPlausible = enableAutoPageviews();
    }

    return () => {
      /**
       * When you call enableAutoPageviews(), it adds some event listeners and overrides history.pushState.
       * To remove them and restore history.pushState, call the cleanup function returned by enableAutoPageviews():
       */

      cleanupPlausible && cleanupPlausible();
    };
  }, [contextApp?.user?.id]);

  return (
    <ApolloProvider client={client}>
      <ContextApp.Provider value={contextApp}>
        <ContextEDHProvider>
          <BookingContextProvider>
            <Router>
              <Auth />
            </Router>
          </BookingContextProvider>
        </ContextEDHProvider>
      </ContextApp.Provider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppDOM />);

serviceWorker.unregister();
