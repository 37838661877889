import React, { ReactNode } from "react";
import { Link as ILink } from "@models/ISchema";
import { Link } from "react-router-dom";
import css from "./linkErrors.module.scss";

export const LINK_HAS_LABEL_ASSIGNED = "LINK_HAS_LABEL_ASSIGNED";
export const LINK_LABEL_ALREADY_IN_USE = "LINK_LABEL_ALREADY_IN_USE";
export const USER_CANNOT_EDIT_LINK = "USER_CANNOT_EDIT_LINK";

interface LinkErrorRecord {
  [reason: string]: (params?: any) => { title: ReactNode; message: ReactNode };
}

export const LINK_ERROR_LABELS: LinkErrorRecord = {
  [LINK_HAS_LABEL_ASSIGNED]: () => ({
    title: "Código ya asignado",
    message: "Esta liga ya tiene un código cliente definido",
  }),
  [LINK_LABEL_ALREADY_IN_USE]: (params: {
    link: ILink;
    scenario: string;
    workspace: string;
    origin: string;
  }) => ({
    title: "Código ya existe en periodo actual",
    message: (
      <>
        Este código ya ha sido utilizado en este proceso por la liga [
        <Link
          className={css.link}
          to={`/scheduler/editor/link/${params?.workspace}/${params?.scenario}/${params?.origin}/${params?.link?.id}`}
        >
          {params?.link?.code}
        </Link>
        ].
      </>
    ),
  }),
  [USER_CANNOT_EDIT_LINK]: () => ({
    title: "No puedes editar la liga",
    message:
      "No se puede editar el código de la liga porque no cuentas con los permisos necesarios.",
  }),
};
