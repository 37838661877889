import { useMemo } from "react";
import { AdaptedGroup, GroupEdition, GroupEditionErrors } from "../models";
import {
  validateCapacityAugmentedForInactive,
  validateCapacityChangeOnCrnWithoutClassrooms,
  validateCapacityGTMaxEfficiency,
  validateCapacityGTSmallerClassrroom,
  validateCapacityLT80MaxEfficiency,
  validateCapacityLTEnrollments,
} from "../utils/validateGroupEdition";
import splitGroupErrors from "../utils/splitGroupErrors";

interface useGetCapacityErrorsProps {
  editionsByGroupId: Record<string, GroupEdition>;
  group: AdaptedGroup;
  subGroups?: AdaptedGroup[];
}

const validateEditions = (
  parentGroup: AdaptedGroup,
  group: AdaptedGroup,
  edition: GroupEdition,
  isSubgroup: boolean,
): GroupEditionErrors => {
  const errorsToOmitOnSubgroups = ["CapacityGTMaxEfficiency", "CapacityLT80MaxEfficiency"];

  const validations = {
    CapacityGTMaxEfficiency: validateCapacityGTMaxEfficiency(parentGroup, edition),
    CapacityLT80MaxEfficiency: validateCapacityLT80MaxEfficiency(parentGroup, edition),
    CapacityGTSmallerClassroom: validateCapacityGTSmallerClassrroom(parentGroup, edition),
    CapacityChangeOnCrnWithoutClassrooms: validateCapacityChangeOnCrnWithoutClassrooms(
      group,
      edition,
    ),
    CapacityLTEnrollments: validateCapacityLTEnrollments(group, edition),
    CapacityAugmentedForInactive: validateCapacityAugmentedForInactive(group, edition),
  };

  if (validations.CapacityChangeOnCrnWithoutClassrooms) {
    validations.CapacityGTSmallerClassroom = false;
  }

  Object.keys(validations).forEach(key => {
    if (
      validations[key] === false ||
      (isSubgroup && group?.referentType === "CAMPUS" && errorsToOmitOnSubgroups.includes(key))
    ) {
      delete validations[key];
    }
  });

  return validations;
};

const useGetCapacityErrors = ({
  group,
  subGroups = [],
  editionsByGroupId = {},
}: useGetCapacityErrorsProps) => {
  const subGroupsByGroupId = useMemo(() => {
    const groupsById = {};

    subGroups?.forEach(subGroup => {
      groupsById[subGroup?.id] = subGroup;
    });

    return groupsById;
  }, [subGroups]);

  console.log(subGroupsByGroupId);

  const efficiencyErrorsByGroupId = useMemo(() => {
    const errorsObj: Record<string, GroupEditionErrors> = {};
    const editionKeys = Object.keys(editionsByGroupId);

    const totalCapacityEditions = editionKeys.reduce((acc, editionKey) => {
      return acc + (editionsByGroupId[editionKey]?.capacity ?? 0);
    }, 0);

    Object.keys(editionsByGroupId).forEach(key => {
      const editions =
        editionsByGroupId[key]?.capacity !== undefined
          ? {
              ...editionsByGroupId[key],
              capacity: totalCapacityEditions,
            }
          : editionsByGroupId[key];

      errorsObj[key] = validateEditions(group, subGroupsByGroupId[key], editions, true);
    });

    const [hardErrors, SoftErrors] = splitGroupErrors(errorsObj);
    const hardErrorKeys = Object?.keys(hardErrors);
    const softErrorKeys = Object?.keys(SoftErrors);

    const hasHardErrors = hardErrorKeys?.length > 0;
    const hasSoftErrors = softErrorKeys?.length > 0;

    const firstHardError = Object.keys(hardErrors?.[hardErrorKeys?.[0]] ?? {})?.[0] || "";
    const firstSoftError = Object.keys(SoftErrors?.[softErrorKeys?.[0]] ?? {})?.[0] || "";

    return {
      hasHardErrors,
      hasSoftErrors,
      errorKey: firstHardError || firstSoftError,
      errorsByGroupId: errorsObj,
    };
  }, [editionsByGroupId, group, subGroupsByGroupId]);

  return efficiencyErrorsByGroupId;
};

export default useGetCapacityErrors;
