import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import cx from "classnames";
import { CourseMultiKey } from "../../../../shared/selectors";
import TermSelect from "../../../../../modules/vacancies/Groups/TermSelect";
import Button from "../../../../../common/components/Button/Button";
import CampusSelect from "@modules/shared/selectors/Selectors/Campus";
import ModalitySelect from "@modules/shared/selectors/Selectors/Modality";
import ProgramSelect from "@modules/shared/selectors/Selectors/Program";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import css from "./selectors.module.scss";

interface ISelectorsReturn {
  idCampus: string;
  idDepartment: string;
  idModality: string;
  idShift: string;
  objLevel: any;
  idProgram: string;
  idCourse: string;
  idTerm: string;
  idCurriculum: string;
}

interface ISelectorsProps {
  onReturn: (vallue: ISelectorsReturn) => void;
}

const Selectors: React.FC<ISelectorsProps> = (props: ISelectorsProps) => {
  const { onReturn } = props;
  const [idTerm, setIdTerm] = useState(null);
  const [objCourse, setObjCourse] = useState<ISelectorsReturn>(null);
  const [selectedCampus, setSelectedCampus] = useState("");
  const [selectedModality, setSelectedModality] = useState("");
  const [selectedProgram, setSelectedProgram] = useState("");

  const isRequiredFieldsSelected = !!(selectedCampus || selectedModality || selectedProgram);
  const courseKey = useMemo(() => `${selectedCampus}-${selectedModality}-${selectedProgram}`, [
    selectedCampus,
    selectedModality,
    selectedProgram,
  ]);

  console.log(courseKey);

  const handleSubmit = () => onReturn({ ...objCourse, idTerm });

  const setTerm = (values: any) => {
    if (values.term) {
      setIdTerm(values.term);
    }
  };

  return (
    <>
      <section className={cx(css.contentAddSection, "container-row")}>
        <div
          className={cx(css.contentAddSection_item, css.contentAddSection_group, "container-row")}
        >
          <CampusSelect
            label
            onCallback={(campus: RegularOption) => setSelectedCampus(campus?.value || "")}
          />
          <ModalitySelect
            label
            shouldHasDepartment={false}
            onCallback={(modality: RegularOption) => setSelectedModality(modality?.value || "")}
          />
          <ProgramSelect
            label
            campus={selectedCampus}
            modality={selectedModality}
            shouldHaveFilters={false}
            filterBy="modality"
            onCallback={(program: RegularOption) => setSelectedProgram(program?.value || "")}
          />
        </div>

        <div className={cx(css.contentAddSection_item, "container-row")}>
          <div className="col_6">
            <CourseMultiKey
              key={courseKey}
              label
              onCallback={newSelectorReturn => setObjCourse(newSelectorReturn)}
              className={css.contentAddSection_item_selectors}
              campusId={selectedCampus}
              modalityId={selectedModality}
              programId={selectedProgram}
              isDisabled={!isRequiredFieldsSelected}
            />
          </div>
        </div>

        <div className={cx(css.contentAddSection_item, "container-row")}>
          <div className="col_3">
            <TermSelect sendValues={(values: any) => setTerm(values)} />
          </div>
        </div>
      </section>
      <div className="col_8">
        <hr />
      </div>
      <footer className={cx(css.footer, "container-row")}>
        <Link to={`/home`} className={cx(css.footer_link)}>
          <Button color="transparent" className={cx(css.footer_btn)}>
            Cancelar
          </Button>
        </Link>
        <Button
          className={cx(css.footer_btn)}
          onClick={handleSubmit}
          disabled={!objCourse || !idTerm || !isRequiredFieldsSelected}
        >
          Seguir
        </Button>
      </footer>
    </>
  );
};

export default Selectors;
