import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useApolloClient } from "react-apollo";
import cx from "classnames";
import { SelectPagination } from "@foris/avocado-ui";
import { Campus } from "@models/ISchema";
import { IParams } from "@models/IParams";
import { ISelectorReturn } from "./ISelectors";
import { RegularOption } from "@foris/avocado-ui/lib/types/models/GenericProps";
import { campusSearch } from "@modules/sections/GroupsManager/graphql/campusSearch.query";
import css from "./selectors.module.scss";

interface CampusSelectProps extends ISelectorReturn {
  defaultCampus?: Campus;
  isDisabled?: boolean;
}

const getCampusOption = (campus: Campus): RegularOption => {
  if (!campus?.code || !campus?.id) {
    return {
      label: "",
      value: "",
    };
  }

  const campusName = campus?.name ? ` - ${campus?.name}` : "";

  return {
    label: `${campus?.code}${campusName}`,
    value: campus?.id,
  };
};

const CampusSelect = (props: CampusSelectProps) => {
  const client = useApolloClient();
  const { onCallback, label, className, placeholder, defaultCampus, isDisabled = false } = props;
  const { scenario }: IParams = useParams();
  const [valueCampus, setValueCampus] = useState(
    defaultCampus ? getCampusOption(defaultCampus) : null,
  );
  const [page, setPage] = useState(0);
  const [prevSearchTerm, setPrevSearchTerm] = useState("");

  const requestItems = useCallback(
    async (searchTerm = "", page = 1) => {
      const size = 20;

      try {
        const variables = {
          query: campusSearch,
          variables: {
            scenarioId: scenario,
            originId: origin,
            filter: {
              pagination: { page, size, searchTerm },
            },
          },
        };

        const { data } = await client.query(variables);
        const dataQuery = data?.data;
        const pageInfo = dataQuery?.groupsManagerCampuses?.pageInfo;
        const campuses = dataQuery?.groupsManagerCampuses?.items;

        const options = (campuses ?? []).map((campus: Campus) => ({
          id: campus?.id,
          value: campus?.id,
          label: `${campus?.code} | ${campus?.name}`,
          self: campus,
        }));

        return { pageInfo, options };
      } catch (error) {
        console.error(error);
        return {};
      }
    },
    [client],
  );

  const loadOptions = async (newSearchTerm: string) => {
    const newSearchPage = prevSearchTerm === newSearchTerm ? page + 1 : 1;

    setPrevSearchTerm(newSearchTerm);
    setPage(newSearchPage);

    const { pageInfo, options } = await requestItems(newSearchTerm, newSearchPage);

    return {
      options: options,
      hasMore: pageInfo?.hasNextPage,
      additional: { page },
    };
  };

  useEffect(() => {
    if (defaultCampus?.id) {
      onCallback?.(valueCampus);
    }
  }, []);

  return (
    <div className={cx(css.selectors, className)}>
      {label && <label className="labelItem">Sede</label>}
      <SelectPagination
        loadOptions={loadOptions}
        value={valueCampus}
        placeholder={placeholder ? placeholder : label ? "Seleccionar" : "Sede"}
        isClearable
        isDisabled={isDisabled}
        onChange={(value: RegularOption) => {
          if (value) {
            setValueCampus(value);
            onCallback?.(value);
          } else {
            setValueCampus(null);
            onCallback?.(value);
          }
        }}
      />
    </div>
  );
};

export default CampusSelect;
