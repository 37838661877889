import React, { useState, useContext, useEffect } from "react";
import { find, groupBy, orderBy } from "lodash";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import cx from "classnames";
import { CardState, Loading } from "@foris/avocado-ui";
import {
  IContextVacancies,
  ContextVacancies,
  IGroupValue,
  IErrorInstructorBundle,
} from "./contextVacancies/ContextVacancies";
import Footer from "./Footer";
import Links from "./Links/Links";
import {
  Bundle,
  Link,
  Group,
  InstructorResponsibilityInput,
  InstructorResponsibilityChangesetInput,
  CubeMutation,
  InstructorLinkValidationErrorUnion,
} from "@models/ISchema";
import InstructorBundle from "@models/Errors/InstructorBundle";
import { IParams } from "@models/IParams";
import { responsabilityMutation } from "./graphql/instructor.queries";
import css from "./vacancies.module.scss";
import "react-tabs/style/react-tabs.css";

export enum VacanciesType {
  instructor = "instructor",
  vacancies = "vacancies",
}

interface IVacanciesGroup {
  active: boolean;
  setActive: (value: boolean) => void;
  dataBundle: Bundle;
}

const VacanciesGroup: React.FC<IVacanciesGroup> = (props: IVacanciesGroup) => {
  const { active, setActive, dataBundle } = props;
  const { origin, scenario }: IParams = useParams();
  const [instructorMutation] = useMutation(responsabilityMutation, {});
  const [loading, setLoading] = useState(false);
  const context: IContextVacancies = useContext(ContextVacancies);
  const [validationArray, setValidationArray] = useState<InstructorLinkValidationErrorUnion[]>(
    null,
  );
  const [disabledConfirm, setDisabledConfirm] = useState(true);
  const [errors, setErrors] = useState(null);
  const [errorTitle, setErrorTitle] = useState("");
  const warningArray = [];

  const canEditSomeGroup = dataBundle
    ? dataBundle.links.some((link: Link) =>
        link.groups.some((group: Group) => group.course.canEdit),
      )
    : false;

  const callMutationHook = async () => {
    setLoading(true);
    try {
      const changeset: InstructorResponsibilityChangesetInput[] = [];
      const arrayInstructorOrigin = [];
      const arrayInstructorMutation = [];
      context.currentLeague.forEach(item => {
        item.instructors.forEach(instructor => {
          const instructors = {
            linkId: item.linkId,
            instructorId: instructor.id,
            teachingHoursPercentage: instructor.teachingHoursPercentage,
            paymentPercentage: instructor.paymentPercentage,
            isPrimary: instructor.isPrimary,
          };
          arrayInstructorOrigin.push(instructors);
        });
      });

      if (context.instructorValues) {
        context.instructorValues.forEach(value => {
          value &&
            value.forEach((instructor: IGroupValue) => {
              const objInstructor: InstructorResponsibilityChangesetInput = {
                linkId: instructor?.linkId,
                instructorId: instructor?.instructor?.id,
                teachingHoursPercentage: instructor.teachingHoursPercentage,
                paymentPercentage: instructor?.paymentPercentage,
                isPrimary: instructor?.isPrimary,
              };
              changeset.push(objInstructor);
            });
        });
      }
      arrayInstructorOrigin.forEach(item => {
        const findInstructor = find(
          changeset,
          instructor =>
            instructor.instructorId === item.instructorId && instructor.linkId === item.linkId,
        );
        if (findInstructor) {
          arrayInstructorMutation.push(findInstructor);
        } else {
          arrayInstructorMutation.push(item);
        }
      });

      const input: InstructorResponsibilityInput = {
        dryRun: false,
        skipValidations: false,
        changeset: arrayInstructorMutation,
      };
      const variables = {
        scenarioId: parseInt(scenario),
        originId: parseInt(origin),
        input,
      };
      const { data } = await instructorMutation({ variables });
      if (data.cube.instructorResponsibilityUpsert.commited) {
        setLoading(false);
        setValidationArray(null);
        context.setErrorsInstructors(null);
        window.location.reload();
      } else {
        console.error("Error InstructorResponsibility");
        const error: CubeMutation = data.cube;
        const validations: InstructorLinkValidationErrorUnion[] =
          error.instructorResponsibilityUpsert.validations;
        setValidationArray(validations);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    const validations: IErrorInstructorBundle[] = [];
    if (validationArray && validationArray.length > 0) {
      validationArray.forEach(item => {
        const validation = new InstructorBundle(item);
        const message = validation.getMessage();
        const findLink = find(context.currentLeague, link => link.linkId === item.meta);
        validations.push({ error: message, link: findLink, item });
        context.setErrorsInstructors(validations);
      });
    } else {
      context.setErrorsInstructors([]);
    }
    const instructorErrors = groupBy(validations, "link.linkId");
    setErrors(instructorErrors);
    setErrorTitle(validations.length > 1 ? `${validations.length} Errores` : "Error");
  }, [validationArray]);

  let orderList = null;
  if (errors && validationArray && validationArray.length > 0) {
    const values = Object.keys(errors).map(key => {
      return { name: errors[key][0].link.codeName, value: errors[key] };
    });
    orderList = orderBy(values, "name");
  }

  return (
    <>
      {loading && <Loading className={css.loader} />}
      <section className={cx(css.content, "container-row")}>
        <Links
          nameGroup={`${dataBundle.code} ${dataBundle.name}`}
          type={VacanciesType.instructor}
          data={context.currentLeague}
          activeEdit={active}
          setDisabledConfirm={setDisabledConfirm}
        />
      </section>
      {/* validations */}
      {errors && validationArray && validationArray.length > 0 ? (
        <CardState typeCard="error" title={errorTitle} className={css.cardError}>
          {orderList &&
            orderList.map(
              (value: { name: string; value: IErrorInstructorBundle[] }, index: number) => {
                return (
                  <ul key={index} className={css.listError}>
                    <li className={css.listError_subTitle}>{`Liga ${value.name}`}</li>
                    {value.value.map(error => (
                      <li key={index} className={css.listError_item}>
                        {error.error.message}
                      </li>
                    ))}
                  </ul>
                );
              },
            )}
        </CardState>
      ) : null}

      <Footer
        activeEdit={active}
        setActiveEdit={setActive}
        canEditSomeGroup={canEditSomeGroup}
        warningsCount={warningArray.length}
        onSave={() => {
          callMutationHook();
        }}
        disabledConfirm={disabledConfirm}
      />
    </>
  );
};

export default VacanciesGroup;
