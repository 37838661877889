import React, { useContext, useEffect } from "react";
import * as R from "ramda";
import cx from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import { AppContext } from "../../context/EditSessionsContext";
import AccordionTag from "./AccordionTag";
import AccordionHeader from "./AccordionHeader";
import { Session, SessionPayload, Section } from "@models/ISchema";
import AssignmentLabeler from "../../models/AssignmentLabeler";
import { Types } from "../../context/result.reducer";
import css from "./accordion.module.scss";
import { EditedSession } from "../../context/formData.reducer";

type SessionsBySectionIdType = {
  [key: string]: {
    edited: (Session | EditedSession)[];
    created: (Session | EditedSession)[];
    deleted: (Session | EditedSession)[];
  };
};

const AccordionSessionSaved = () => {
  const { state, dispatch } = useContext(AppContext);
  const selectSession = state?.result?.selectSession;
  const selectNewSession = state?.result?.selectCreateSession;
  const sessionToDeleteSelected = state?.result?.sessionToDeleteSelected;
  const savedSessionsToCreateIds = R.reduce(
    (acc, session) => R.assoc(session?.id, true, acc),
    {},
    state?.form?.sessionsToCreate,
  );
  const sessionsWithErrorsById = R.pipe(
    R.filter((payload: SessionPayload) => {
      return (
        Boolean(payload?.intervalValidationErrors?.length) ||
        Boolean(payload?.validationErrors?.length) ||
        Boolean(payload?.customValidations?.length)
      );
    }),
    R.map(payload => {
      return payload?.sessionUuid in savedSessionsToCreateIds
        ? payload?.sessionUuid
        : payload?.sessionId;
    }),
    R.reduce((acc, id) => R.assoc(id, true, acc), {}),
  )(
    R.concat(
      state?.result?.createValidation?.sessionsPayload ?? [],
      state?.result?.resultValidation?.sessionsPayload ?? [],
    ),
  );

  const sectionsById: { [key: string]: Section } = R.reduce(
    (acc, session: Session | EditedSession) =>
      R.assoc(
        session?.section?.id ?? (session as EditedSession)?.session?.section?.id,
        session?.section ?? (session as EditedSession)?.session?.section,
        acc,
      ),
    {},
    R.pipe(
      R.concat(state?.form?.savedSessions ?? []),
      R.concat(R.values(state?.form?.sessionsToDelete) ?? []),
      R.concat(state?.form?.sessionsToCreate ?? []),
    )([]),
  );

  const hasError = R.has(R.__, sessionsWithErrorsById);

  const sessionsBySectionId: SessionsBySectionIdType = R.reduce(
    (acc, session: EditedSession) => {
      const sectionId = session?.section?.id || session?.session?.section?.id;
      if (R.not(R.has(sectionId, acc))) {
        acc[sectionId] = { edited: [], created: [], deleted: [] };
      }
      if (R.has(session.id, state?.form?.sessionsToDelete)) acc[sectionId].deleted.push(session);
      else if (session.id.includes("-")) acc[sectionId].created.push(session);
      else acc[sectionId].edited.push(session.session);
      return acc;
    },
    {},
    R.pipe(
      R.concat(state?.form?.savedSessions ?? []),
      R.concat(R.values(state?.form?.sessionsToDelete) ?? []),
      R.concat(state?.form?.sessionsToCreate ?? []),
    )([]),
  );

  useEffect(() => {
    if (selectSession) {
      dispatch({ type: Types.SelectSession, payload: selectSession });
    }
    if (selectNewSession) {
      dispatch({ type: Types.SelectCreateSession, payload: selectNewSession });
    }
  }, [selectSession, selectNewSession]);

  if (
    !state?.form?.savedSessions?.length &&
    !R.keys(state?.form?.sessionsToDelete)?.length &&
    !state?.form?.sessionsToCreate?.length
  ) {
    return null;
  }

  return (
    <Accordion
      preExpanded={R.keys(sectionsById).map((_, index) => index.toString())}
      allowMultipleExpanded
      className={cx(css.accordion)}
    >
      {R.toPairs(sessionsBySectionId).map(([sectionId, sessions], index) => (
        <AccordionItem uuid={index.toString()} key={sectionId} className={cx(css.accordionItem)}>
          <AccordionItemHeading className={cx(css.accordionHeader)}>
            <AccordionItemButton
              className={cx("container-row", "row--between", "row_align--start")}
            >
              <AccordionHeader section={sectionsById[sectionId]} updated={false} />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className={cx(css.accordionPanel)}>
            {sessions?.edited?.map(session => {
              const savedSession = state.form?.savedSessions
                ? R.find(sessionState => sessionState.id === session.id, state.form?.savedSessions)
                : null;
              return (
                <AccordionTag
                  key={session.id}
                  color={index + 1}
                  session={session as Session}
                  selected={session?.id === selectSession?.id}
                  weeks={AssignmentLabeler.fromWeeks(
                    (session as Session).assignment.intervals,
                    savedSession?.intervals,
                    state.link.weeks,
                  )}
                  date={AssignmentLabeler.fromDate(
                    (session as Session).assignment,
                    savedSession?.blocks,
                  )}
                  onClick={session => {
                    dispatch({ type: Types.SelectSession, payload: session });
                  }}
                  icon={hasError(session?.id) ? "circle-full-error" : "circle-full-check"}
                />
              );
            })}
            {sessions?.created?.map(session => {
              return (
                <AccordionTag
                  key={session.id}
                  color={11}
                  editedSession={session}
                  selected={session?.id === selectNewSession?.id}
                  weeks={AssignmentLabeler.fromWeeks(
                    null,
                    (session as EditedSession)?.intervals,
                    state?.link?.weeks,
                  )}
                  date={AssignmentLabeler.fromDate(null, (session as EditedSession)?.blocks)}
                  onClick={(_, value) => {
                    dispatch({ type: Types.SelectCreateSession, payload: value });
                  }}
                  icon={hasError(session?.id) ? "circle-full-error" : "circle-full-check"}
                />
              );
            })}
            {sessions?.deleted?.map(sessionToDelete => (
              <AccordionTag
                key={sessionToDelete.id}
                color={28}
                deleted={true}
                editedSession={sessionToDelete}
                selected={sessionToDelete?.id === sessionToDeleteSelected?.id}
                weeks={AssignmentLabeler.fromWeeks(
                  null,
                  (sessionToDelete as EditedSession)?.intervals,
                  state?.link?.weeks,
                )}
                date={AssignmentLabeler.fromDate(null, (sessionToDelete as EditedSession)?.blocks)}
                onClick={(_, clickedSession) => {
                  dispatch({ type: Types.SelectDeletedSession, payload: clickedSession });
                }}
                icon={"trash"}
              />
            ))}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};

export default AccordionSessionSaved;
