import React, { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useApolloClient, useMutation } from "react-apollo";
import cx from "classnames";
import { IErrorWarning } from "../../../../../models/Errors/ErrorWarning";
import Button from "../../../../../common/components/Button/Button";
import ComponentItem, { IReturnComponent } from "./ComponentItem";
import { COMPONENT_LIST } from "../graphql/components.queries";
import Validations from "../../../../../common/components/Validations/Validations";
import { NEW_LINK_CODE, NEW_LINK_SECTION } from "../graphql/links.queries";
import {
  DataQuery,
  CourseComponent,
  CubeMutation,
  CreateLinkPayload,
} from "../../../../../models/ISchema";
import { IParams } from "../../../../../models/IParams";
import css from "./addLinkSection.module.scss";

interface IAddLinkSection {
  courseId: number;
  termId: number;
  onReturn: () => void;
}

const CreateLink: React.FC<IAddLinkSection> = ({ courseId, termId, onReturn }: IAddLinkSection) => {
  const client = useApolloClient();
  const { scenario, origin, workspace }: IParams = useParams();
  const history = useHistory();
  const [componentList, setComponentList] = useState<CourseComponent[]>(null);
  const [createSections, setCreateSections] = useState<IReturnComponent[]>(null);
  const [codeLinkMutation] = useMutation(NEW_LINK_CODE, {});
  const [newLinkSectionMutation] = useMutation(NEW_LINK_SECTION, {});
  const [validation, setValidation] = useState(null);

  /**
   * Array Validations Error || Warning
   */
  const validationErrorWarning: IErrorWarning[] = validation ? [...validation] : [];

  /**
   * Validations create section (example)
   */
  const validations = () => {
    let returnDisabled = true;
    let countOldSection = 0;
    let countNotSelect = 0;
    let countNewSection = 0;
    const componentCount = componentList ? componentList.length : null;
    let objValidation: IErrorWarning = {
      type: "ERROR",
      message: "Error genérico",
    };

    createSections.forEach(value => {
      if (value.typeSection === "OLD_SECTION") {
        countOldSection++;
        if (!value.section) countNotSelect++;
      }
      if (value.typeSection === "NEW_SECTION") countNewSection++;
    });
    if (componentCount && createSections.length === componentCount) {
      // validate one new_section
      if (countNewSection === 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere al menos una sección nueva.",
        };
        validationErrorWarning.push(objValidation);
        return true;
      } else {
        returnDisabled = false;
      }
      // validate old_section and select a section
      if (countOldSection > 0 && countNotSelect > 0) {
        objValidation = {
          type: "ERROR",
          message: "Se requiere seleccionar alguna sección.",
        };
        validationErrorWarning.push(objValidation);
        return true;
      } else {
        returnDisabled = false;
      }
    }
    return returnDisabled;
  };

  /**
   * Get new link code of section
   */
  const getCodeLink = async () => {
    try {
      const variables = {
        originId: origin,
        scenarioId: scenario,
        courseId: courseId,
      };
      const { data } = await codeLinkMutation({ variables });
      const code: CubeMutation = data.cube;
      return code.inferLinkCode;
    } catch (e) {
      console.debug(e);
    }
  };

  /**
   * Generate new Section by Link
   */
  const newSectionByLink = async () => {
    try {
      const newCode = await getCodeLink();
      const assignSections = [];
      const courseComponentId = [];
      const errorCreateLink = [];

      createSections.forEach(value => {
        if (value.typeSection === "OLD_SECTION") {
          assignSections.push(parseInt(value.section.value));
        }
        if (value.typeSection === "NEW_SECTION") {
          courseComponentId.push({
            courseComponentId: parseInt(value.courseComponent.id),
          });
        }
      });
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: {
          dryRun: false,
          skipValidations: true,
          courseId: courseId,
          termId: termId,
          code: newCode,
          assignSections,
          createSections: courseComponentId,
        },
      };
      const { data } = await newLinkSectionMutation({ variables });
      const payload: CreateLinkPayload = data.cube.createLink;
      if (payload.commited) {
        client.resetStore();
        const contextUrl = `${workspace}/${scenario}/${origin}`;
        history.push(`/editor/vacancies/${contextUrl}/${payload.link.bundle.id}`);
      } else {
        payload.validationErrors &&
          payload.validationErrors.forEach(() => {
            const objValidate: IErrorWarning = {
              type: "ERROR",
              message: "Ha ocurrido un error, por favor vuelva a cargar la página.",
            };
            errorCreateLink.push(objValidate);
          });
        setValidation(errorCreateLink);
      }
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * List of components by courseId
   */
  const getComponentList = useCallback(async () => {
    try {
      const variables = {
        scenarioId: scenario,
        filter: {
          fields: {
            courseId: {
              eq: courseId,
            },
          },
        },
      };
      const { data } = await client.query({ query: COMPONENT_LIST, variables });
      const components: DataQuery = data.data;
      setComponentList(components.courseComponents.items);
    } catch (e) {
      console.error(e);
    }
  }, [scenario, client, courseId]);

  useEffect(() => {
    if (componentList === null) getComponentList();
  }, [getComponentList, componentList]);

  useEffect(() => {
    if (!createSections && componentList) {
      const sections = [];
      componentList.forEach(value => {
        const component: IReturnComponent = {
          courseComponent: value,
          typeSection: "NEW_SECTION",
          section: null,
        };
        sections.push(component);
      });
      setCreateSections(sections);
    }
  }, [createSections, componentList]);

  return (
    <>
      {componentList && createSections && (
        <section className={cx(css.cntCreateLink, "container-row")}>
          {componentList.length === 0 ? (
            <h2 className={cx(css.cntEmpty)}>
              La asignatura seleccionada no tiene componentes configurados.
            </h2>
          ) : (
            <>
              <h3 className={cx(css.cntCreateLink_title, "col_12")}>Componentes:</h3>
              <ul className={cx(css.listComponent, "container-row")}>
                {componentList &&
                  componentList.map(value => {
                    return (
                      <ComponentItem
                        key={value.id}
                        courseComponent={value}
                        courseId={courseId}
                        onCallback={value => {
                          const sections = [...createSections];
                          const findComponent = sections.findIndex(
                            cmp => cmp.courseComponent.id === value.courseComponent.id,
                          );
                          if (findComponent >= 0) sections.splice(findComponent, 1);
                          sections.push(value);
                          setCreateSections(sections);
                        }}
                      />
                    );
                  })}
              </ul>
              <Validations
                validations={validationErrorWarning}
                onCallback={(valueCheck: boolean) => console.debug(valueCheck)}
                className={css.validationItem}
              />
            </>
          )}
          <footer className={cx(css.footer, "container-row")}>
            <Button className={css.footer_btn} color="transparent" onClick={() => onReturn()}>
              Cancelar
            </Button>
            {componentList.length > 0 && (
              <Button
                className={css.footer_btn}
                disabled={validations()}
                onClick={() => newSectionByLink()}
              >
                Crear
              </Button>
            )}
          </footer>
        </section>
      )}
    </>
  );
};

export default CreateLink;
