import gql from "graphql-tag";

export const settingsQuery = gql`
  query settings($filter: SettingFilterInput) {
    base {
      settings(filter: $filter) {
        items {
          id
          config
          value
        }
      }
    }
  }
`;
