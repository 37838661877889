import React, { useState, useContext, useEffect, useMemo } from "react";
import * as R from "ramda";
import { Card, Icon, Button } from "@foris/avocado-ui";
import { Loader } from "../../../../../../common/components/Loader/Loader";
import { AppContext } from "../../../context/EditSessionsContext";
import {
  AdaptedRecommendationAlternative,
  RecommendationsBySessionId,
} from "../../../context/linkData.reducer";
import { Types as FormDataTypes } from "../../../context/formData.reducer";
import cx from "classnames";
import css from "./recommendations.module.scss";
import { Section } from "@models/ISchema";

const Recommendations = () => {
  const { dispatch, state } = useContext(AppContext);
  const [recommendations, setRecommendations] = useState<RecommendationsBySessionId>({});
  const [displayRecommendations, setDisplayRecommendations] = useState(false);

  useMemo(() => {
    const sessionIds: Set<string> = R.pipe(
      R.map((section: Section) => {
        const sessionIds: string[] = R.map(R.prop("id"), section?.sessions);
        const unasignedSessionsIds: string[] = R.map(R.prop("id"), section?.unasignedSessions);
        return R.concat(sessionIds, unasignedSessionsIds) as string[];
      }),
      sessionIds => new Set(R.flatten(sessionIds)),
    )(state?.link?.sections ?? []);

    // we don't want to query the recommendations again after some sessions were
    // deleted, so remove any recommendation that belongs to a session that
    // doesn't exist on the link anymore
    const filteredRecommendations = R.filter(
      ({ session }) => sessionIds.has(session?.id),
      state?.link?.recommendations ?? {},
    );

    setRecommendations(filteredRecommendations);
  }, [state?.link?.recommendations, state?.link?.info]);

  /**
   * Set sessionId recommendation by default
   */
  useEffect(() => {
    if (!state?.link?.recommendations || Boolean(state?.form?.sessionRecommendationIdSelected))
      return;

    const sessionIds = R.keys(state?.link?.recommendations);

    if (R.isEmpty(sessionIds)) return;

    dispatch({
      type: FormDataTypes.SetSessionRecommendationIdSelected,
      payload: R.head(sessionIds).toString(),
    });
  }, [state?.link?.recommendations]);

  const separator = <div className={css.recommendations_content_separator}></div>;

  const sessionIdsButtons = (recommendations: RecommendationsBySessionId): React.ReactNode => {
    const buttonColorByIdx = (idx: number, sessionId: string): string =>
      cx(
        css[`color_${idx % 10}`],
        sessionId === state?.form?.sessionRecommendationIdSelected
          ? css[`color_${idx % 10}_selected`]
          : "",
      );

    return R.addIndex(R.map)(
      ([sessionId, recommendation]: any, idx: number) => (
        <Button
          key={sessionId}
          onClick={() => {
            dispatch({
              type: FormDataTypes.SetSessionRecommendationIdSelected,
              payload: sessionId,
            });
          }}
          className={cx(
            css.recommendations_content_sessions_ids_item,
            buttonColorByIdx(idx, sessionId),
          )}
          outline={false}
          typeButton={
            sessionId === state?.form?.sessionRecommendationIdSelected ? "blue" : "transparent"
          }
        >
          {sessionId} ({recommendation?.alternatives?.length ?? 0})
        </Button>
      ),
      R.toPairs(recommendations),
    );
  };

  const recommendationInfo = () => {
    const selectedSessionId = state?.form?.sessionRecommendationIdSelected;
    const recommendations = state?.link?.recommendations;
    if (!selectedSessionId || !recommendations) return <></>;

    const recommendation = recommendations[selectedSessionId];
    const processId = recommendation?.processId;
    const sessionId = recommendation?.session?.id;
    const duration = recommendation?.session?.duration;
    const packs = recommendation?.session?.packages;
    const assignedWeeks = recommendation?.assignedWeeks;

    return (
      <div className={css.recommendations_content_info_rows}>
        <div className={css.recommendations_content_info_rows_row}>
          <h4 className={css.recommendations_content_info_rows_row_title}>Asignación (proceso):</h4>
          <p className={css.recommendations_content_info_rows_row_data}>{processId}</p>
        </div>

        <div className={css.recommendations_content_info_rows_row}>
          <h4 className={css.recommendations_content_info_rows_row_title}>Sesión ID:</h4>
          <p className={css.recommendations_content_info_rows_row_data}>{sessionId}</p>
        </div>

        <div className={css.recommendations_content_info_rows_row}>
          <h4 className={css.recommendations_content_info_rows_row_title}>
            Duración de la sesión:
          </h4>
          <p className={css.recommendations_content_info_rows_row_data}>{duration ?? 0} Bloques</p>
        </div>

        <div className={css.recommendations_content_info_rows_row}>
          <h4 className={css.recommendations_content_info_rows_row_title}>
            Paquetes de la sesión:
          </h4>
          {packs.length ? (
            packs?.map((pack: string) => (
              <p key={pack} className={css.recommendations_content_info_rows_row_data}>
                {pack}
              </p>
            ))
          ) : (
            <></>
          )}
        </div>

        <div className={css.recommendations_content_info_rows_row}>
          <h4 className={css.recommendations_content_info_rows_row_title}>Semandas asignadas:</h4>
          <p className={css.recommendations_content_info_rows_row_data}>
            {assignedWeeks?.length ?? 0}
          </p>
        </div>
      </div>
    );
  };

  const alternativeItem = (alternative: AdaptedRecommendationAlternative, idx: number) => {
    const schedule = `${R.join(", ", alternative?.days)} - ${alternative?.startTime}`;
    const instructors = alternative?.instructors?.length
      ? R.join(", ", alternative?.instructors)
      : "-";
    const classrooms = alternative?.classrooms?.length
      ? R.join(", ", alternative?.classrooms)
      : "-";

    return (
      <div key={idx} className={css.alternative}>
        <div className={css.alternative_row}>
          <h4 className={css.alternative_row_title}>Horario:</h4>
          <p className={css.alternative_row_data}>{schedule}</p>
        </div>

        <div className={css.alternative_row}>
          <h4 className={css.alternative_row_title}>Docentes posibles:</h4>
          <p className={css.alternative_row_data}>{instructors}</p>
        </div>

        <div className={css.alternative_row}>
          <h4 className={css.alternative_row_title}>Salas posibles:</h4>
          <p className={css.alternative_row_data}>{classrooms}</p>
        </div>
      </div>
    );
  };

  const alternatives = () => {
    const selectedSessionId = state?.form?.sessionRecommendationIdSelected;
    const recommendations = state?.link?.recommendations;
    if (!selectedSessionId || !recommendations) return <></>;
    const alternatives = recommendations[selectedSessionId]?.alternatives;
    return alternatives.map(alternativeItem);
  };

  const getNumberOfAlternatives = () => {
    const selectedSessionId = state?.form?.sessionRecommendationIdSelected;
    const recommendations = state?.link?.recommendations;
    if (!selectedSessionId || !recommendations) return <></>;
    return recommendations[selectedSessionId]?.alternatives?.length ?? 0;
  };

  return (
    <Card.Simple className={css.recommendations}>
      <Card.Header className={css.recommendations_header}>
        <Button
          disabled={R.isEmpty(recommendations)}
          onClick={() => setDisplayRecommendations(!displayRecommendations)}
          className={cx(css.recommendations_unoutlined_button)}
          typeButton={"transparent"}
        >
          <div className={cx(css.recommendations_unoutlined_button_chevron)}>
            Recomendación de asignación
          </div>
          {R.isNil(state?.link?.recommendations) ? (
            <Loader size="tiny" className={cx(css.recommendations_loader)} />
          ) : (
            <Icon size={16} icon={displayRecommendations ? "chevron-up" : "chevron-down"} />
          )}
        </Button>
      </Card.Header>
      {displayRecommendations ? (
        <Card.Content className={css.recommendations_content}>
          <section className={css.recommendations_content_sessions}>
            <h3 className={css.recommendations_content_title}>
              {Object.keys(recommendations ?? {}).length} Sesiones:
            </h3>
            <div className={css.recommendations_content_sessions_ids}>
              {sessionIdsButtons(recommendations)}
            </div>
          </section>

          {separator}

          <section className={css.recommendations_content_info}>{recommendationInfo()}</section>

          {separator}

          <section className={css.recommendations_content_alternativesContainer}>
            <h3 className={css.recommendations_content_title}>
              Alternativas compatibles {getNumberOfAlternatives()}:
            </h3>
            <div className={css.recommendations_content_alternativesContainer_alternatives}>
              {alternatives()}
            </div>
          </section>
        </Card.Content>
      ) : (
        <Card.Content className={cx(css.recommendations_hiddenContent)}>{}</Card.Content>
      )}
    </Card.Simple>
  );
};

export default Recommendations;
