import {
  GroupsManagerEnrollmentsOrderByFieldsEnum,
  GroupsManagerEnrollmentsSearchableFieldsEnum,
  OrderByDirection,
} from "@models/ISchema";
import { TableFiltersReducerType } from "../context/types";
import { EnrollmentsTableColumn } from "../models";

const columnToField = {
  Estudiante: GroupsManagerEnrollmentsSearchableFieldsEnum.Student,
  Carrera: GroupsManagerEnrollmentsSearchableFieldsEnum.Program,
  Campus: GroupsManagerEnrollmentsSearchableFieldsEnum.Campus,
  Estado: GroupsManagerEnrollmentsSearchableFieldsEnum.State,
  Fecha: GroupsManagerEnrollmentsSearchableFieldsEnum.Date,
};

const enrollmentsTableHeaderToSearchByObj = (
  header: EnrollmentsTableColumn,
): TableFiltersReducerType["orderBy"] => {
  if (header == null || !(header in columnToField)) {
    return {
      header: "Estudiante",
      field: (columnToField["Estudiante"] as unknown) as GroupsManagerEnrollmentsOrderByFieldsEnum,
      direction: OrderByDirection.Desc,
    };
  }

  return {
    header,
    field: (columnToField[header] as unknown) as GroupsManagerEnrollmentsOrderByFieldsEnum,
    direction: OrderByDirection.Desc,
  };
};

export default enrollmentsTableHeaderToSearchByObj;
