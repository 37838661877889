import gql from "graphql-tag";

export const linkInfoFragment = gql`
  fragment LinkInfo on LinkInfo {
    link {
      id
      code
      vacancies
      groups {
        id
        type
      }
      packages {
        id
        code
        index
        vacancies
        complete
        hasPackageRules
        type
        isPublished
        isReplicated
        sourcePopulationCode
        population {
          id
          code
          size
          level
          packageCount
          term {
            id
            name
          }
          campus {
            id
            code
            name
          }
          modality {
            id
            code
            name
          }
          shift {
            id
            code
            name
          }
          program {
            id
            code
            name
            label
          }
          curriculum
        }
      }
      bundle {
        id
        code
        label
        name
        term {
          id
          code
          name
          start_date
          end_date
        }
      }
      course {
        id
        code
        label
        name
        level
        credits
        generatesPackages
        courseComponents {
          id
          component {
            id
            code
            name
          }
        }
        curriculum {
          id
          code
          name
          program {
            id
            code
            name
            modality {
              id
              code
            }
            campus {
              id
              code
              name
              isGlobal
            }
            department {
              id
              code
            }
            shift {
              id
              code
            }
          }
        }
      }
      totalBlocks
    }
  }
`;
