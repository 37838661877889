import { Package } from "@models/ISchema";
import { FiltersType } from "../components/Filters/Filters";
import { SelectableOption } from "../models";
import { FiltersSelectorsType } from "./types";

export const setFilterToDisplay = (payload: FiltersType, state: FiltersSelectorsType) => {
  return {
    ...state,
    visibleFilter: payload,
  };
};

export const setSelectedPackage = (
  payload: SelectableOption<Package> | null,
  state: FiltersSelectorsType,
) => {
  return {
    ...state,
    selectedPackage: payload,
  };
};

export const setUsedFilterToSearch = (payload: FiltersType, state: FiltersSelectorsType) => {
  return {
    ...state,
    usedFilterToSearch: payload,
  };
};
