import { useState, useContext } from "react";
import * as R from "ramda";
import { useParams } from "react-router-dom";
import { useMutation } from "react-apollo";
import {
  Day,
  EditorOperation,
  ChangeClassroomInputType,
  ChangeInstructorInputType,
  ChangeIntervalsInputType,
  ChangeBlocksInputType,
  EditorChangesetInput,
} from "@models/ISchema";
import { savableSessionsToCreate } from "../utils/context";
import { IParams } from "@models/IParams";
import { AppContext } from "../context/EditSessionsContext";
import { sessionsCrud } from "../graphql/sessionsCrud.mutation";
import { BlockRanges, EditedSession } from "../context/formData.reducer";
import { Week } from "../context/linkData.reducer";

interface MutationProps {
  dryRun: boolean;
  skipValidations: boolean;
}

const useSessionsCrudMutation = (props: MutationProps): any => {
  const { dryRun, skipValidations } = props;
  const { scenario, origin }: IParams = useParams();
  const { state } = useContext(AppContext);

  const [sessionsCrudMutation] = useMutation(sessionsCrud, {});
  const [result, setResult] = useState(null);
  const [error, setError] = useState(false);

  const blocksByHours = (blocks: BlockRanges): ChangeBlocksInputType => {
    const day = blocks?.day?.toLowerCase()?.replace(/^\w/, (c: string) => c.toUpperCase());
    return {
      op: EditorOperation.Change,
      day: Day[day],
      startTime: blocks?.startTime,
      endTime: blocks?.endTime,
    };
  };

  const changeClassroom: (session: EditedSession) => ChangeClassroomInputType = R.applySpec({
    op: R.always(EditorOperation.Change),
    classroomIds: R.pipe(R.propOr([], "classrooms"), R.map(R.prop<"id">("id"))),
  });

  const changeInstructor: (session: EditedSession) => ChangeInstructorInputType = R.applySpec({
    op: R.always(EditorOperation.Change),
    instructorIds: R.pipe(R.propOr([], "instructors"), R.map(R.prop<"id">("id"))),
  });

  const changeIntervals: (session: EditedSession) => ChangeIntervalsInputType = R.applySpec({
    op: R.always(EditorOperation.Change),
    intervalIds: R.pipe<any[], Week[], Week[], string[]>(
      R.propOr([], "intervals"),
      R.filter(R.propOr(false, "checked")),
      R.map(R.prop<"id", string>("id")),
    ),
    uniqueInterval: R.F,
  });

  const buildSessionChangeset = R.curry(
    (isCreation: boolean, session: EditedSession): EditorChangesetInput => {
      const blocks =
        session?.blocks?.selected === "hours"
          ? { changeBlocks: blocksByHours(session?.blocks) }
          : { noScheduleEventsCount: session?.blocks?.blocks };

      return {
        sessionId: session?.id,
        sectionId: isCreation ? session?.section?.id : null,
        ...blocks,
        changeClassroom: changeClassroom(session),
        changeInstructor: changeInstructor(session),
        changeIntervals: changeIntervals(session),
      };
    },
  );

  const setVariables = () => {
    const hasBeenDeleted = R.pipe(
      R.propOr("-", "id"),
      R.flip(R.has)(state?.form?.sessionsToDelete ?? {}),
    );

    const { updates, creates } = R.pipe(
      R.reject(hasBeenDeleted),
      R.reduce(
        ({ updates, creates }, curr) => ({
          creates: !!curr?.isNew ? R.append(curr, creates) : creates,
          updates: !curr?.isNew ? R.append(curr, updates) : updates,
        }),
        { updates: [], creates: [] },
      ),
    )(state?.form?.savedSessions);

    // Add the "cloned" sessions to the "creates" array
    creates.push(...savableSessionsToCreate(state?.form?.sessionsToCreate));

    return {
      dryRun,
      skipValidations,
      changesets: {
        updates: R.map(buildSessionChangeset(false), updates),
        creates: R.map(buildSessionChangeset(true), creates),
        deletes: R.keys(state?.form?.sessionsToDelete ?? {}),
      },
    };
  };

  const submitSessionsCrud = async () => {
    try {
      const variables = {
        scenarioId: scenario,
        originId: origin,
        input: setVariables(),
      };
      const { data } = await sessionsCrudMutation({ variables });
      setResult(data?.cube?.sessionsCrud);
    } catch (error) {
      setError(true);
      console.error("[SessionsCrud]: ", error);
    }
  };

  return [{ data: result, error }, submitSessionsCrud];
};

export default useSessionsCrudMutation;
